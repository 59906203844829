import { InlineMath } from "react-katex";
import "katex/dist/katex.min.css";

export const DictionarySource = [
  {
    category:
      "Выбросы CH4 и CO2 при добыче угля подземным способом (по данным компонентного состава углеводородной смеси)",
    documentation: (
      <a href="https://docs.cntd.ru/document/350962750" target="_blank">
        Открыть
      </a>
    ),
    formula: (
      <InlineMath math="E_{(i,y)} = \sum (FC_y \times W_{(i,y)} \times \rho_i \times 10^{- 2})" />
    ),
    type: "Фугитивные выбросы",
    status: "Активен",
  },
  {
    category: "Выбросы CO2 при добыче угля открытым способом",
    documentation: (
      <a href="https://docs.cntd.ru/document/350962750" target="_blank">
        Открыть
      </a>
    ),
    formula: (
      <InlineMath math="E_{(CO_{2,y})} = \sum_{(j,b,y)} (FC_{(j,b,y)} \times EF_{(j,b)})" />
    ),
    type: "Выбросы от транспорта",
    status: "Активен",
  },
  {
    category: "Выбросы CH4 при обращении с углем, добытым подземным способом",
    documentation: (
      <a
        href="https://www.ipcc-nggip.iges.or.jp/public/2006gl/russian/index.html"
        target="_blank"
      >
        Открыть
      </a>
    ),
    formula: (
      <>
        {" "}
        Выбросы СН4 = коэффициент СН4 × добыча угля × коэффициент преобразования
      </>
    ),
    type: "Фугитивные выбросы",
    status: "Активен",
  },
  {
    category: "Выбросы CH4 при добыче угля открытым способом",
    documentation: (
      <a
        href="https://www.ipcc-nggip.iges.or.jp/public/2006gl/russian/index.html"
        target="_blank"
      >
        Открыть
      </a>
    ),
    formula: (
      <>
        Выбросы СН4 = коэффициент СН4 × добыча угля × коэффициент преобразования
      </>
    ),
    type: "Фугитивные выбросы",
    status: "Активен",
  },
  {
    category:
      "Выбросы CH4 при добыче угля подземным способом (с учетом коэффициента эмисиии метана)",
    documentation: (
      <a
        href="https://www.ipcc-nggip.iges.or.jp/public/2006gl/russian/index.html"
        target="_blank"
      >
        Открыть
      </a>
    ),
    formula: (
      <>
        Выбросы СН4 = коэффициент СН4 × добыча угля × коэффициент преобразования
      </>
    ),
    type: "Фугитивные выбросы",
    status: "Активен",
  },
  {
    category:
      "Выбросы CH4 утилизированного в факеле или на стационарных источниках",
    documentation: (
      <a
        href="https://www.ipcc-nggip.iges.or.jp/public/2006gl/russian/index.html"
        target="_blank"
      >
        Открыть
      </a>
    ),
    formula: (
      <>
        Выбросы СН4 = объем рекуперированного метана × коэффициент
        преобразования
      </>
    ),
    type: "Фугитивные выбросы",
    status: "Активен",
  },
  {
    category: "Выбросы CO2 от сжигания CH4 на стационарных источниках",
    documentation: (
      <a
        href="https://www.ipcc-nggip.iges.or.jp/public/2006gl/russian/index.html"
        target="_blank"
      >
        Открыть
      </a>
    ),
    formula: (
      <InlineMath math="E_{CO_{2,y}} = \textstyle\sum_{j=1}^n (FC_{j,y} \times EF_{CO_{2,j,y}} \times OF_{j,y} \times k)" />
    ),
    type: "Стационарное сжигание",
    status: "Активен",
  },
  {
    category: "Выбросы CO2 от сжигания CH4 в факеле",
    documentation: (
      <a
        href="https://www.ipcc-nggip.iges.or.jp/public/2006gl/russian/index.html"
        target="_blank"
      >
        Открыть
      </a>
    ),
    formula: (
      <>
        Выбросы СО2 от сжигания CH4 в факеле = 0.98 × объем сожженого в факеле
        метана × коэффициент преобразования × стехиометрический коэффициент
        массы
      </>
    ),
    type: "Стационарное сжигание",
    status: "Активен",
  },
];

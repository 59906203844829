
import projectImage from "../../Style/image/project.svg";
import dictionaryImage from "../../Style/image/dictionary.svg";
import documentImage from "../../Style/image/documentsIcon.svg";
import exportImage from "../../Style/image/export.svg";
import settingsImage from "../../Style/image/settings-sidebar.svg";
import exitImage from "../../Style/image/exitApp.svg";
import profile from "../../Style/image/profile.svg";
import calculation from "../../Style/image/calculator.svg";
export const SidebarSource = [
  {
    header: "Профиль",
    image: profile,
    navigate: "/profile"
  },
    {
      header: "Проекты",
      image: projectImage,
      navigate: "/projects"
    },
  
    {
      header: "Справочники",
      image: dictionaryImage,
      navigate: "/dictionary"
    },
    {
      header: "Нормативные документы",
      image: documentImage,
      navigate: "/documents"
    },
    {
      header: "Экспорт",
      image: exportImage,
      navigate: "/export"
    },
    {
      header: "Виджеты",
      image: calculation,
      navigate: "/widgets"
    },
    {
      header: "Настройки",
      image: settingsImage,
      navigate: "/settings"
    },
  ];
 export const SidebarSoucreFooter = [
    {
      header: "Выход",
      image: exitImage,
      navigate: "/login"
    },
  ];
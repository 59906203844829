import { makeAutoObservable, runInAction } from "mobx";
import { ICommonStore } from "../Store/commonStore";
import http from "../Pages/HttpServices/http";
//IDisposalCO2Stat
export interface IDisposalCO2StatDictionary{
    id: number;
    name: string;
    ef: number;
    of: number;
    k: number;
}
//IDisposalCO2Torch
export interface IDisposalCO2TorchDictionary{
    id: number;
    name: string;
    ef: number;
    koef_transform: number;
}
//ITorch
export interface ITorchCH4Dictionary{
    id: number;
    name: string;
    koef_transform: number;
} 
//IEnrichment
export interface IEnrichmentData{
    id: number;
    coal_proc: string;
    concentrate: string;
    date: string;
    mine: number;
}
export interface IEnrichment{
    id: number;
    data: IEnrichmentData[]
    name: string;
}
//IGasContent
export interface IGasContentData{
    id: number;
    depth: number;
    max_gas: string;
    min_gas: string;
    date: string;
    mine: number;
}
export interface IGas{
    id: number;
    data: IGasContentData[]
    name: string;
    group: string;
}
//IMethaneAbundance
export interface IMineMethaneAbundanceData{
    id: number;
    a_metan: string;
    o_metan: string;
    date: string;
    mine: number;
}
export interface IMethaneAbundance{
    id: number;
    data: IMineMethaneAbundanceData[];
    name: string;
}
//IHandlingUndergroundCH4
export interface IHandlingUndergroundCH4Dictionary{
    id: number;
    name: string;
    ef: number;
    koef_transform: number;
}
//IOpenMiningCH4
export interface IOpenMiningCH4Dictionary{
    id: number;
    name: string;
    ef: number | undefined;
    koef_transform: number;
}
//IOpenMiningCO2
export interface IOpenMiningCO2Dictionary{
    id: number;
    name: string;
    ef: number;
}
//IUnderground
export interface IUndergroundDictionary{
    id: number;
    name: string;
    p_co2: number;
    p_ch4: number;
}
//IUndergroundContent
export interface IUndergroundContentDictionary{
    id: number;
    name: string;
    ef_co2: number | string;
    ef_ch4: number | string;
}
//IUndergroundEmissionDictionary
export interface IUndergroundEmissionDictionary{
    id: number;
    name: string;
    koef_transform: number;
}
//IDensityCoeffDictionary
export interface ICategoryFuel{
    id: number;
    name: string;
}
export interface IDensityCoeffDictionary{
    id: number;
    category: ICategoryFuel;
    name: string;
    t_co2_t: number;
    kg_tj: number;
    kg_tut: number;
    p: number;
}
export interface IDictionaryStore{
    enrichments: IEnrichment[];
    gases: IGas[];
    methaneAbundances: IMethaneAbundance[];
    handlingUndergroundCH4: IHandlingUndergroundCH4Dictionary[];
    openMiningCH4: IOpenMiningCH4Dictionary[];
    openMiningCO2: IOpenMiningCO2Dictionary[];
    underground: IUndergroundDictionary[];
    undergroundContent: IUndergroundContentDictionary[];
    undergroundEmission: IUndergroundEmissionDictionary[];
    disposalCO2Stat: IDisposalCO2StatDictionary[];
    disposalCO2Torch: IDisposalCO2TorchDictionary[];
    torchCH4: ITorchCH4Dictionary[];
    densityCoeff: IDensityCoeffDictionary[];


    fetchEnrichments: () => Promise<void>;
    fetchGases: () => Promise<void>;
    fetchMethaneAbundances: () => Promise<void>;
    fetchHandlingUndergroundCH4: () => Promise<void>;
    fetchOpenMiningCH4: () => Promise<void>;
    fetchOpenMiningCO2: () => Promise<void>;
    fetchUnderground: () => Promise<void>;
    fetchUndergroundContent: () => Promise<void>;
    fetchUndergroundEmission: () => Promise<void>;
    fetchDisposalCO2Stat: () => Promise<void>;
    fetchDisposalCO2Torch: () => Promise<void>;
    fetchTorch: () => Promise<void>;
    fetchDensityCoeff: () => Promise<void>;
}

export class DictionaryStore implements IDictionaryStore{
    enrichments: IEnrichment[] = [];
    gases: IGas[] = [];
    methaneAbundances: IMethaneAbundance[] = [];
    handlingUndergroundCH4: IHandlingUndergroundCH4Dictionary[] = [];
    openMiningCH4: IOpenMiningCH4Dictionary[] = [];
    openMiningCO2: IOpenMiningCO2Dictionary[] = [];
    underground: IUndergroundDictionary[] = [];
    undergroundContent: IUndergroundContentDictionary[] = [];
    undergroundEmission: IUndergroundEmissionDictionary[] = [];
    disposalCO2Stat: IDisposalCO2StatDictionary[] = [];
    disposalCO2Torch: IDisposalCO2TorchDictionary[] =[];
    torchCH4: ITorchCH4Dictionary[] =[];
    densityCoeff: IDensityCoeffDictionary[] =[];

    constructor(private common: ICommonStore){
        makeAutoObservable(this);
    }
    public clean(){
        this.enrichments = [];
        this.gases = [];
        this.methaneAbundances = [];
        this.handlingUndergroundCH4 = [];
        this.openMiningCH4 = [];
        this.openMiningCO2 = [];
        this.underground = [];
        this.undergroundContent = [];
        this.undergroundEmission = [];
        this.disposalCO2Stat = [];
        this.disposalCO2Torch = [];
        this.torchCH4 = [];
        this.densityCoeff = [];
    }
    public fetchDensityCoeff = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IDensityCoeffDictionary[]>(
                "/v1/dictionary/get_coeff_co2_density/"
            )
            runInAction(() => this.densityCoeff = res )
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchDisposalCO2Stat = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IDisposalCO2StatDictionary[]>(
                "/v1/dictionary/get_disposal_co2_stat_sources/"
            )
            runInAction(() => this.disposalCO2Stat = res )
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchDisposalCO2Torch = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IDisposalCO2TorchDictionary[]>(
                "/v1/dictionary/get_disposal_co2_torch/"
            )
            runInAction(() => this.disposalCO2Torch = res )
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchTorch = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<ITorchCH4Dictionary[]>(
                "/v1/dictionary/get_torch_ch4/"
            )
            runInAction(() => this.torchCH4 = res )
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchEnrichments = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IEnrichment[]>(
                "/v1/dictionary/get_enrichment/"
            )
            runInAction(() => this.enrichments = res)
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchGases = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IGas[]>(
                "/v1/dictionary/get_gas_content/"
            )
            runInAction(() => this.gases = res)
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchMethaneAbundances = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IMethaneAbundance[]>(
                "/v1/dictionary/get_methane_abundance/"
            )
            runInAction(() => this.methaneAbundances = res)
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchHandlingUndergroundCH4 = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IHandlingUndergroundCH4Dictionary[]>(
                "/v1/dictionary/get_handling_underground_ch4/"
            )
            runInAction(() => this.handlingUndergroundCH4 = res)
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchUndergroundEmission = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IUndergroundEmissionDictionary[]>(
                "/v1/dictionary/get_underground_emission_ch4/"
            )
            runInAction(() => this.undergroundEmission = res)
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchOpenMiningCH4 = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IOpenMiningCH4Dictionary[]>(
                "/v1/dictionary/get_open_mining_ch4/"
            )
            runInAction(() => this.openMiningCH4 = res )
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchOpenMiningCO2 = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IOpenMiningCO2Dictionary[]>(
                "/v1/dictionary/get_open_mining_co2/"
            )
            runInAction(() => this.openMiningCO2 = res )
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchUnderground = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IUndergroundDictionary[]>(
                "/v1/dictionary/get_underground/"
            )
            runInAction(() => this.underground = res)
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
    public fetchUndergroundContent = async() => {
        try{
            this.common.setIsLoading(true);
            const res = await http.get<IUndergroundContentDictionary[]>(
                "/v1/dictionary/get_underground_content/"
            )
            runInAction(() => this.undergroundContent = res)
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false);
        }
    };
}
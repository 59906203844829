import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import { IProject } from "../../../StoreCollections/projectStore";
import { appStore } from "../../../Store/appStore";

interface IUpdateProjectProps {
  show: boolean;
  onClose: () => void;
  onUpdate: (updatedProject: any) => void;
  selectedProject?: IProject;
}

const UpdateProject: React.FC<IUpdateProjectProps> = ({
  show,
  onClose,
  onUpdate,
  selectedProject,
}) => {
  const { userStore } = appStore;
  const [name, setName] = React.useState("");
  const [start_date, setStart_date] = React.useState("");
  const [end_date, setEnd_date] = React.useState("");

  useEffect(() => {
    setName(selectedProject?.name || "");
    setStart_date(selectedProject?.start_date || "");
    setEnd_date(selectedProject?.end_date || "");
    userStore.getInfo();
    return () => {
      userStore.infoClear();
    };
  }, [show]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim() === "") {
      console.log("Please enter project name");
      return;
    }
    if (!start_date || !end_date) {
      console.log("Please select both start and end dates");
      return;
    }
    const updatedProject = {
      id: selectedProject?.id,
      name: name,
      start_date: start_date,
      end_date: end_date,
      company: userStore.user?.company.id,
      user: userStore.user?.id,
    };
    onUpdate(updatedProject);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Обновление проекта</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>Название проекта</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Form.Label>Дата начала проекта</Form.Label>
        <Form.Control
          type="date"
          value={start_date}
          onChange={(e) => setStart_date(e.target.value)}
        />
        <Form.Label>Дата окончания проекта</Form.Label>
        <Form.Control
          type="date"
          value={end_date}
          onChange={(e) => setEnd_date(e.target.value)}
        />
        <Stack gap={3}>
          <Button
            className="mt-3"
            style={{ backgroundColor: "green" }}
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Обновить проект
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Закрыть
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default observer(UpdateProject);

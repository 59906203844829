import { Spinner } from "react-bootstrap";
import { observer } from "mobx-react-lite";

interface LoadingProps {
  text?: string;
}

const Loading = ({ text = "" }: LoadingProps) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <label className="mr-3">Загрузка {text}...</label>
      <Spinner animation="border" role="status" />
    </div>
  );
};
export default observer(Loading);

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { appStore } from "../../Store/appStore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "../../Style/ButtonColorStyle.css";

export const Login = observer(() => {
  const { userStore } = appStore;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [guardant, setGuardant] = useState("1111");
  const navigate = useNavigate();
  useEffect(() => {
    if (userStore.checkToken()) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (email === "testtest@mail.ru") {
      setGuardant("3333");
    }
  }, [email]);

  const handleLogin = async () => {
    try {
      const isSuccess = await userStore.login(email, password, guardant);
      if (isSuccess) {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container
      fluid="xxl"
      className="vh-100  d-flex align-items-center justify-content-center"
    >
      <Row className="silverBG">
        <Col className="text-center">
          <Row className="gradient-background">
            <Form.Label>
              Цифровая платформа для расчета фугитивных выбросов
            </Form.Label>
          </Row>
          <Row className="m-2">
            <Form.Label>Логин</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Row>
          <Row className="m-2">
            <Form.Label>Пароль</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Row>
          <Row className="justify-content-center m-2">
            <Button
              className="button-size greenButton mt-2"
              onClick={handleLogin}
            >
              Вход
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
});

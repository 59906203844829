import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import { ITorchCH4Dictionary } from "../../../../StoreCollections/dictionaryStore";
import Loading from "../../../Loading/Loading";
import { regex } from "./RegExpr";
import { InlineMath } from "react-katex";

interface TorchCH4Props {
  onSelectedDictionaryChange: (value: ITorchCH4Dictionary | null) => void;
  onVolumeChange: (value: number) => void;
  mode: string;
  selectedCalculation: any;
}

const TorchCH4Calculation: React.FC<TorchCH4Props> = ({
  onSelectedDictionaryChange,
  onVolumeChange,
  mode,
  selectedCalculation,
}) => {
  const { dictionaryStore, commonStore } = appStore;

  const [selectedDictionary, setSelectedDictionary] =
    useState<ITorchCH4Dictionary | null>(null);
  const [volume, setVolume] = useState("");

  useEffect(() => {
    dictionaryStore.fetchTorch();
    return () => {
      dictionaryStore.clean();
    };
  }, []);

  useEffect(() => {
    if (dictionaryStore.torchCH4 && dictionaryStore.torchCH4.length > 0) {
      setSelectedDictionary(dictionaryStore.torchCH4[0]);
    }
  }, [dictionaryStore.torchCH4]);

  useEffect(() => {
    onSelectedDictionaryChange(selectedDictionary);
  }, [selectedDictionary]);

  useEffect(() => {
    onVolumeChange(Number(volume));
  }, [volume]);

  useEffect(() => {
    if (mode === "edit") {
      if (selectedCalculation) {
        setVolume(Number(selectedCalculation.volume).toFixed(4));
      }
    }
  }, [selectedCalculation]);

  useEffect(() => {
    if (
      dictionaryStore.torchCH4.length > 0 &&
      mode === "edit" &&
      selectedCalculation != undefined
    ) {
      const foundCalc = dictionaryStore.torchCH4.find(
        (calc: ITorchCH4Dictionary) =>
          Number(calc.koef_transform) ===
          Number(selectedCalculation.koef_transform)
      );
      if (foundCalc) {
        setSelectedDictionary(foundCalc);
      } else {
        console.log("No matching calculation found");
      }
    }
  }, [dictionaryStore.torchCH4]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = dictionaryStore.torchCH4.find(
      (item) => item.id === Number(event.target.value)
    );
    setSelectedDictionary(selectedItem ?? null);
  };

  if (commonStore.isLoading) {
    return <Loading text="полей" />;
  }

  return (
    <>
      <Form.Label>
        Объем рекуперированного метана, тыс.
        <InlineMath math="м^{3}" />
      </Form.Label>
      <Form.Control
        value={volume}
        onChange={(e) => {
          if (regex.test(e.target.value)) {
            setVolume(e.target.value);
          }
        }}
        type="text"
      />
    </>
  );
};

export default observer(TorchCH4Calculation);

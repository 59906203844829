import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import { IDisposalCO2StatDictionary } from "../../../../StoreCollections/dictionaryStore";
import Loading from "../../../Loading/Loading";
import { regex } from "./RegExpr";
import { InlineMath } from "react-katex";

interface DisposalCO2StatProps {
  onSelectedDictionaryChange: (
    value: IDisposalCO2StatDictionary | null
  ) => void;
  onVolumeChange: (value: number) => void;
  mode: string;
  selectedCalculation: any;
}

const DisposalCO2StatCalculation: React.FC<DisposalCO2StatProps> = ({
  onSelectedDictionaryChange,
  onVolumeChange,
  mode,
  selectedCalculation,
}) => {
  const { dictionaryStore, commonStore } = appStore;

  const [selectedDictionary, setSelectedDictionary] =
    useState<IDisposalCO2StatDictionary | null>(null);
  const [volume, setVolume] = useState("");

  useEffect(() => {
    dictionaryStore.fetchDisposalCO2Stat();
    return () => {
      dictionaryStore.clean();
    };
  }, []);

  useEffect(() => {
    if (
      dictionaryStore.disposalCO2Stat &&
      dictionaryStore.disposalCO2Stat.length > 0
    ) {
      setSelectedDictionary(dictionaryStore.disposalCO2Stat[0]);
    }
  }, [dictionaryStore.disposalCO2Stat]);

  useEffect(() => {
    onSelectedDictionaryChange(selectedDictionary);
  }, [selectedDictionary]);

  useEffect(() => {
    onVolumeChange(Number(volume));
  }, [volume]);

  useEffect(() => {
    if (mode === "edit") {
      if (selectedCalculation) {
        setVolume(Number(selectedCalculation.volume).toFixed(4));
      }
    }
  }, [selectedCalculation]);

  useEffect(() => {
    if (
      dictionaryStore.disposalCO2Stat.length > 0 &&
      mode === "edit" &&
      selectedCalculation != undefined
    ) {
      const foundCalc = dictionaryStore.disposalCO2Stat.find(
        (calc: IDisposalCO2StatDictionary) =>
          Number(calc.ef) === Number(selectedCalculation.ef) &&
          Number(calc.of) === Number(selectedCalculation.of) &&
          Number(calc.k) === Number(selectedCalculation.k)
      );
      if (foundCalc) {
        setSelectedDictionary(foundCalc);
      } else {
        console.log("No matching calculation found");
      }
    }
  }, [dictionaryStore.disposalCO2Stat]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = dictionaryStore.disposalCO2Stat.find(
      (item) => item.id === Number(event.target.value)
    );
    setSelectedDictionary(selectedItem ?? null);
  };

  if (commonStore.isLoading) {
    return <Loading text="полей" />;
  }

  return (
    <>
      <Form.Label>
        Расход CH4 за выбранный период, тыс.
        <InlineMath math="м^{3}" />
      </Form.Label>
      <Form.Control
        value={volume}
        onChange={(e) => {
          if (regex.test(e.target.value)) {
            setVolume(e.target.value);
          }
        }}
        type="text"
      />
    </>
  );
};

export default observer(DisposalCO2StatCalculation);

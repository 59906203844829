import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import { IProject, IProjectNew } from "../../../StoreCollections/projectStore";
import { appStore } from "../../../Store/appStore";

interface ICreateProjectProps {
  show: boolean;
  onClose: () => void;
  onCreate: (createProject: any) => void;
}

const CreateProject: React.FC<ICreateProjectProps> = ({
  show,
  onClose,
  onCreate,
}) => {
  const { userStore } = appStore;
  const [name, setName] = React.useState("");
  const [start_date, setStart_date] = React.useState<Date | null>(null);
  const [end_date, setEnd_date] = React.useState<Date | null>(null);

  useEffect(() => {
    userStore.getInfo();
    return () => {
      userStore.infoClear();
    };
  }, [show]);

  const resetForm = () => {
    setName("");
    setStart_date(null);
    setEnd_date(null);
  };

  const handleSubmit = (e: React.FormEvent) => {
    const project: IProjectNew = {
      name,
      start_date: start_date?.toISOString().split("T")[0],
      end_date: end_date?.toISOString().split("T")[0],
      status: 1,
      company: userStore.user?.company.id,
      user: userStore.user?.id,
    };
    e.preventDefault();
    if (name.trim() === "") {
      console.log("Please enter project name");
      return;
    }
    if (!start_date || !end_date) {
      console.log("Please select both start and end dates");
      return;
    }
    onCreate(project);
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      onExited={() => {
        resetForm();
      }}
    >
      <Modal.Header>
        <Modal.Title>Создать новый проект</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>Название проекта</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Form.Label>Дата начала проекта</Form.Label>
        <Form.Control
          type="date"
          value={start_date ? start_date?.toISOString().split("T")[0] : ""}
          onChange={(e) => {
            const dateValue = e.target.value;
            setStart_date(dateValue ? new Date(dateValue) : null);
          }}
        />
        <Form.Label>Дата окончания проекта</Form.Label>
        <Form.Control
          type="date"
          value={end_date ? end_date?.toISOString().split("T")[0] : ""}
          onChange={(e) => {
            const dateValue = e.target.value;
            setEnd_date(dateValue ? new Date(dateValue) : null);
          }}
        />
        <Stack gap={3}>
          <Button
            className="mt-3"
            style={{ backgroundColor: "green" }}
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Создать проект
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Закрыть
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default observer(CreateProject);

import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Pagination, Row, Table } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { exportFile } from "./ExportService";
import { IProject } from "../../StoreCollections/projectStore";
import { observer } from "mobx-react-lite";
import { appStore } from "../../Store/appStore";

const ExportFile = () => {
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [currentProject, setCurrentProject] = useState<IProject>();
  const [currentPage, setCurrentPage] = useState(1);
  const [exportType, setExportType] = useState<string | null>("");
  const [selectedTypeIndex, setSelectedTypeIndex] = useState<number | null>(
    null
  );
  const [selectedProjectIndex, setSelectedProjectIndex] = useState<
    number | null
  >(null);

  const { commonStore, projectStore } = appStore;

  useEffect(() => {
    projectStore.fetchProjects();
    return () => {
      projectStore.clean();
    };
  }, []);

  const handleRowClick = (type: string, index: any) => {
    setSelectedType(type);
    setExportType(`в ${type} формате`);
    setSelectedTypeIndex(index);
  };
  const handleRowProjectClick = (project: IProject, index: any) => {
    setProjectId(project.id);
    setCurrentProject(project);
    setSelectedProjectIndex(index);
  };

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    projectStore.setOffset((page - 1) * projectStore.limit);
    await projectStore.fetchProjects();
  };

  const exportData = (detail: boolean) => {
    if (selectedType === "txt" && projectId !== null) {
      exportFile(currentProject?.name ?? "Отчет", "txt", projectId, detail);
    } else if (selectedType === "xls" && projectId !== null) {
      exportFile(currentProject?.name ?? "Отчет", "xls", projectId, detail);
    } else if (selectedType === "xlsx" && projectId !== null) {
      exportFile(currentProject?.name ?? "Отчет", "xlsx", projectId, detail);
    } else {
      console.error("Project ID is required.");
    }
  };

  return (
    <Col className="mt-3">
      <Row className="row-bg">
        <Col>
          <Row className="mb-2 add-border">
            <Col className="mt-2 mb-2 ">Экспорт</Col>
          </Row>
          <Row>
            <Col className="header-border-start">Тип экспорта</Col>
            <Col className="header-border">Тип файла</Col>
          </Row>
          {[
            {
              category: "Экспорт в формате txt",
              type: "txt",
            },
            {
              category: "Экспорт в формате xls",
              type: "xls",
            },
            {
              category: "Экспорт в формате xlsx",
              type: "xlsx",
            },
          ].map((item, index) => (
            <Row key={index} className="mt-3 mb-3 item-row">
              <Col
                className="header-border-start"
                onClick={() => handleRowClick(item.type, index)}
                style={{
                  backgroundColor:
                    selectedTypeIndex === index ? "#9FA6B2" : "initial",
                }}
              >
                {item.category}
              </Col>
              <Col
                className="header-border"
                onClick={() => handleRowClick(item.type, index)}
                style={{
                  backgroundColor:
                    selectedTypeIndex === index ? "#9FA6B2" : "initial",
                }}
              >
                {item.type}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      {selectedType && (
        <>
          <Row className="mt-3 mb-3">
            <Col>
              <Row className="row-bg">
                <label className="text-center formLabel">{`Выбрать проект для экспорта ${exportType}`}</label>
              </Row>
            </Col>
          </Row>
          {commonStore.isLoading ? (
            <div style={{ minHeight: "387px" }}>Загрузка проектов...</div>
          ) : (
            <>
              <Col>
                <Row className="row-bg">
                  <Col className="header-border-start">
                    Наименование проекта
                  </Col>
                  <Col className="header-border">Начало периода</Col>
                  <Col className="header-border">Конец периода</Col>
                </Row>
                {projectStore.projects.map((item, index) => (
                  <Row
                    key={index}
                    onClick={() => handleRowProjectClick(item, index)}
                    className="mt-3 row-bg item-row"
                    style={{
                      backgroundColor:
                        selectedProjectIndex === index ? "#9FA6B2" : "initial",
                    }}
                  >
                    <Col className="item-border-start">{item.name}</Col>
                    <Col className="item-border">{item.start_date}</Col>
                    <Col className="item-border">{item.end_date}</Col>
                  </Row>
                ))}
              </Col>
              <Row>
                <Pagination className="mt-3 item-row-pagination">
                  {Array.from(
                    {
                      length: Math.ceil(
                        Number(projectStore.count) / projectStore.limit
                      ),
                    },
                    (_, i) => (
                      <Pagination.Item
                        onClick={() => handlePageChange(i + 1)}
                        key={i}
                        active={i + 1 === currentPage}
                      >
                        {i + 1}
                      </Pagination.Item>
                    )
                  )}
                </Pagination>
              </Row>
              <Row className="row-bg">
                <Button
                  className="greenButton mt-2"
                  variant="primary"
                  onClick={() => exportData(false)}
                >
                  {`Экспорт данных ${exportType}`}
                </Button>
                {exportType !== "в txt формате" && (
                  <>
                    <Button
                      className="greenButton mt-2"
                      variant="primary"
                      onClick={() => exportData(true)}
                    >
                      {`Детализированный экспорт данных ${exportType}`}
                    </Button>
                  </>
                )}
              </Row>
            </>
          )}
        </>
      )}
    </Col>
  );
};
export default observer(ExportFile);

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import Loading from "../../../Loading/Loading";
import { regex } from "./RegExpr";
import { InlineMath } from "react-katex";

interface UndergroundEmissionProps {
  onVolumeChange: (value: number) => void;
  onEmissionChange: (value: number) => void;
  mode: string;
  selectedCalculation: any;
}

const UndergroundEmissionCalculation: React.FC<UndergroundEmissionProps> = ({
  onVolumeChange,
  onEmissionChange,
  mode,
  selectedCalculation,
}) => {
  ///Variables
  const { commonStore } = appStore;

  const [volume, setVolume] = useState("");
  const [emission, setEmission] = useState("");

  ///useEffects
  useEffect(() => {
    onVolumeChange(Number(volume));
  }, [volume]);

  useEffect(() => {
    onEmissionChange(Number(emission));
  }, [emission]);

  useEffect(() => {
    if (mode === "edit") {
      if (selectedCalculation) {
        setVolume(Number(selectedCalculation.volume).toFixed(4));
        setEmission(Number(selectedCalculation.ef).toFixed(4));
      }
    }
  }, [selectedCalculation]);

  if (commonStore.isLoading) {
    return <Loading text="Полей" />;
  }

  return (
    <>
      <Form.Label>Объем угля, добытого подземным способом, т</Form.Label>
      <Form.Control
        value={volume}
        onChange={(e) => {
          if (regex.test(e.target.value)) {
            setVolume(e.target.value);
          }
        }}
        type="text"
      />
      <Form.Label>
        Коэффициент выбросов CH4, <InlineMath math="м^{3}" />
        /т
      </Form.Label>
      <Form.Control
        value={emission}
        onChange={(e) => {
          if (regex.test(e.target.value)) {
            setEmission(e.target.value);
          }
        }}
        type="text"
      />
    </>
  );
};

export default observer(UndergroundEmissionCalculation);

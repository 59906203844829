import { useEffect } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { Login } from "./Pages/Login/Login";
import { observer } from "mobx-react-lite";
import { appStore } from "./Store/appStore";
import ProtectedRoute from "./Pages/ProtectedRoute/ProtectedRoute";
const App = observer(() => {
  const { userStore } = appStore;

  useEffect(() => {
    userStore.checkToken();
  }, []); //eslint-disable-line

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/backend" element={<Navigate to="/admin" replace />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute authenticationPath="/login" outlet={<Home />} />
          }
        />
      </Routes>
    </>
  );
});
export default App;

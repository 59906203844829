import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import Loading from "../../../Loading/Loading";

interface SensitivityProps {
  mode: string;
  selectedCalculation: any;
  onFileChange: (value: File) => void;
}

const SensitivityCalculation: React.FC<SensitivityProps> = ({
  mode,
  selectedCalculation,
  onFileChange,
}) => {
  ///Variables
  const { commonStore } = appStore;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  ///useEffects
  useEffect(() => {
    if (selectedFile !== null) {
      onFileChange(selectedFile);
    }
  }, [selectedFile]);

  ///Methods

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  if (commonStore.isLoading) {
    return <Loading text="Полей" />;
  }

  return (
    <>
      {mode !== "edit" && (
        <>
          <Form.Label>Файл с данными</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </>
      )}
    </>
  );
};

export default observer(SensitivityCalculation);

import { useEffect, useState } from "react";
import "./Settings.css";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { observer } from "mobx-react-lite";

const ExportFile = () => {
  const [comma, setComma] = useState(4);
  useEffect(() => {
    if (localStorage.getItem("comma")) {
      setComma(
        localStorage.getItem("comma")
          ? parseInt(localStorage.getItem("comma")!)
          : 4
      );
    }
  }, []);

  const handleSave = () => {
    localStorage.setItem("comma", comma.toString());
  };
  return (
    <Col>
      <Row className="row-bg silverBG">
        <Col>
          <Row>
            <label className="text-center formLabel">Настройки</label>
          </Row>
          <Row className="row-test">
            <Stack gap={1}>
              <Form.Label className="text-center">
                Введите количество знаков после запятой
              </Form.Label>
              <Form.Control
                placeholder="Количество знаков после запятой"
                className="m-2 text-center input-label"
                value={comma}
                onChange={(e) => setComma(parseInt(e.target.value))}
              />
              <Button className="greenButton m-2" onClick={handleSave}>
                Сохранить
              </Button>
            </Stack>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default observer(ExportFile);

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Pagination, Row, Stack } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { appStore } from "../../Store/appStore";
import { observer } from "mobx-react-lite";
import CreateEditCalculation from "./components/CreateEditCalculation";
import Loading from "../Loading/Loading";
import ShowCalculation from "./components/ShowCalculation";

const CalculationRegistry: React.FC = () => {
  const { calcStore, projectStore, commonStore } = appStore;

  const { projectId } = useParams<{ projectId: string }>();

  const [isVisible, setIsVisible] = useState(true);

  const [options] = useState([
    { type: "undefined", name: "Выбрать реестр расчетов" },
    {
      type: "handling_underground_ch4",
      name: "Расчет выбросов CH4 при последующем обращении с углем, добытым подземным способом",
    },
    {
      type: "open_mining_ch4",
      name: "Расчет выбросов CH4 при добыче угля открытым способом",
    },
    {
      type: "open_mining_co2",
      name: "Расчет выбросов CO2 при добыче угля открытым способом (транспорт)",
    },
    {
      type: "underground",
      name: "Расчет выбросов CH4 и CO2 при добыче угля подземным способом (по данным компонентного состава углеводородной смеси)",
    },
    {
      type: "underground_emission_ch4",
      name: "Расчет выбросов СН4 при добыче угля подземным способом (с учетом коэффициента эмиссии метана)",
    },
    {
      type: "disposal_co2_torch",
      name: "Расчет выбросов CO2 от сжигания CH4 в факеле",
    },
    {
      type: "disposal_co2_stat_sources",
      name: "Расчет выбросов CO2 от сжигания CH4 на стационарных источниках",
    },
    {
      type: "torch_ch4",
      name: "Расчет выбросов CH4, утилизированного в факеле или на стационарных источниках",
    },
    {
      type: "regres_methane",
      name: "Прогнозирование загазирований горных выработок в зависимости от горно-геологических и горнотехнических условий угольных шахт",
    },
    {
      type: "filling_blanks",
      name: "Восстановление значений временных рядов объемов добычи угля подземным и открытым способами, метанообильность шахт, объемов обогащений и транспортировки",
    },
    {
      type: "sensitivity",
      name: "Оценка чувствительности объемов фугитивных выбросов парниковых газов к изменению газоносности угольных шахт, объемов добычи, переработки и транспортировки угля",
    },
  ]);

  const [selectedOption, setSelectedOption] = useState("");

  const [selectedCalculation, setSelectedCalculation] =
    useState<any>(undefined);

  const [showModalCreate, setShowModalCreate] = useState(false);

  const [showModalResult, setShowModalResult] = useState(false);

  const [mode, setMode] = useState("");

  useEffect(() => {
    projectStore.fetchProject(Number(projectId));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption !== "undefined" && selectedOption.trim() !== "") {
      calcStore.fetchCalculations(selectedOption, Number(projectId));
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    return () => {
      calcStore.clean();
    };
  }, [selectedOption, showModalCreate, showModalResult]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleCreateCalculation = () => {
    setMode("create");
    setShowModalCreate(true);
  };

  const handleEditCalculation = (calculation: any) => {
    setMode("edit");
    setSelectedCalculation(calculation);
    setShowModalCreate(true);
  };

  const handleShowResultModal = (calculation: any) => {
    setSelectedCalculation(calculation);
    setShowModalResult(true);
  };

  const handleCloseResultModal = () => {
    setShowModalResult(false);
  };

  const handleCloseCreateModal = () => {
    setShowModalCreate(false);
    setMode("");
  };

  const handleSubmitForm = async (formData: any) => {
    try {
      if (
        (selectedOption === "regres_methane" && formData.file_data) ||
        (selectedOption === "filling_blanks" && formData.file_data) ||
        (selectedOption === "sensitivity" && formData.file_data)
      ) {
        await calcStore.createCalculationWithFile(selectedOption, formData);
      } else {
        await calcStore.createCalculation(selectedOption, formData);
      }
      handleCloseCreateModal();
      calcStore.fetchCalculations(selectedOption, Number(projectId));
    } catch (error) {
      console.error("Ошибка при создании расчета:", error);
    }
  };
  const transformRegresData = (data: any[]): { data: any[] } => {
    return {
      data: data.map((item) => ({
        id: item.id,
        ...Object.fromEntries(
          Object.entries(item).map(([key, value]) => [key, value])
        ),
      })),
    };
  };

  const handleEditForm = async (formData: any) => {
    try {
      await calcStore.updateCalculation(selectedOption, formData.id, formData);
      if (selectedOption === "regres_methane" && formData.data) {
        const transformedData = transformRegresData(formData.data);
        await calcStore.updateRegresDataCalculation(
          selectedOption,
          formData.id,
          transformedData
        );
      }
      handleCloseCreateModal();
      calcStore.fetchCalculations(selectedOption, Number(projectId));
    } catch (error) {
      console.error("Ошибка при создании расчета:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    calcStore.setOffset((page - 1) * calcStore.limit);
    await calcStore.fetchCalculations(selectedOption, Number(projectId));
  };

  return (
    <>
      <CreateEditCalculation
        show={showModalCreate}
        project_id={Number(projectId)}
        selectedType={selectedOption}
        mode={mode}
        onCreate={handleSubmitForm}
        onEdit={handleEditForm}
        onClose={handleCloseCreateModal}
        selectedCalculation={selectedCalculation}
      />

      <ShowCalculation
        show={showModalResult}
        project_id={Number(projectId)}
        selectedType={selectedOption}
        selectedCalculation={selectedCalculation}
        onClose={handleCloseResultModal}
      />
      <Col>
        <Row className="row-bg">
          <Col>
            <Row className="mb-2 add-border">
              <Col className="mt-2 mb-2">
                Расчеты - {projectStore.selectedProject?.name}
              </Col>
            </Row>

            <Row className="mb-2 add-border">
              <Col className="mt-2 mb-2">
                <Stack>
                  <Form.Select value={selectedOption} onChange={handleChange}>
                    {options.map((option) => (
                      <option key={option.type} value={option.type}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                  {isVisible && (
                    <Button
                      className="item-buttom greenButton"
                      onClick={() => {
                        handleCreateCalculation();
                      }}
                    >
                      + Добавить
                    </Button>
                  )}
                </Stack>
              </Col>
            </Row>
            {commonStore.isLoading ? (
              <Loading text="расчётов" />
            ) : (
              <>
                {calcStore.calculations.length === 0 ? (
                  <>Список расчетов пуст</>
                ) : (
                  <>
                    <Row>
                      <Col className="header-border-start">
                        Наименование расчета
                      </Col>
                      <Col className="header-border">Функции</Col>
                    </Row>
                    {calcStore.calculations.map((calc) => (
                      <Row
                        key={calc.id}
                        className="mt-3 item-row"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleShowResultModal(calc);
                        }}
                      >
                        <Col className="item-border-start">
                          <span className="card-title" title={calc.name}>
                            {calc.name.length > 50
                              ? `${calc.name.substring(0, 50)}...`
                              : calc.name}
                          </span>
                        </Col>
                        <Col className="item-border">
                          <Stack direction="horizontal" gap={2}>
                            <Button
                              className="item-buttom yellowButton"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleEditCalculation(calc);
                              }}
                            >
                              Редактировать
                            </Button>
                            <Button
                              className="item-buttom redButton"
                              onClick={async (event) => {
                                event.stopPropagation();
                                await calcStore.deleteCalculation(
                                  selectedOption,
                                  calc.id,
                                  calc
                                );
                              }}
                            >
                              Удалить
                            </Button>
                          </Stack>
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Pagination className="mt-3 item-row-pagination">
                        {Array.from(
                          {
                            length: Math.ceil(
                              Number(calcStore.count) / calcStore.limit
                            ),
                          },
                          (_, i) => (
                            <Pagination.Item
                              onClick={() => handlePageChange(i + 1)}
                              key={i}
                              active={i + 1 === currentPage}
                            >
                              {i + 1}
                            </Pagination.Item>
                          )
                        )}
                      </Pagination>
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default observer(CalculationRegistry);

import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { appStore } from "../../Store/appStore";

// Определяем типы
export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

// Создаем функцию-обертку
const ProtectedRoute = ({
  authenticationPath,
  outlet,
}: ProtectedRouteProps): React.ReactElement => {
  const { userStore } = appStore;
  const shouldNavigate = userStore.checkToken();

  return !shouldNavigate ? <Navigate to={authenticationPath} /> : outlet;
};

// Обертываем компонент в observer для использования MobX
export default observer(ProtectedRoute);

import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { appStore } from "../../Store/appStore";
import { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import { Bar, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement, // Register PointElement
  LineElement, // Register LineElement for Line char
} from "chart.js";
import { IRegress } from "../../StoreCollections/calcStore";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement, // Added for Line chart
  LineElement, // Added for Line chart
  annotationPlugin
);

const Graph = () => {
  const { calcStore, projectStore, commonStore } = appStore;
  const { projectId } = useParams<{ projectId: string }>();
  const [calcUndergroundResults, setCalcUndergroundResults] = useState<any[]>(
    []
  );
  const [calcOpenDieselResults, setCalcOpenDieselResults] = useState<any[]>([]);
  const [calcOpenFuelResults, setCalcOpenFuelResults] = useState<any[]>([]);
  const [calcRegressgBlanksResult, setCalcRegressBlanksResult] = useState<
    IRegress[]
  >([]);

  useEffect(() => {
    projectStore.fetchProject(Number(projectId));
  }, [projectId]);

  //Выбросы при подземной добыче угля инфографика
  useEffect(() => {
    calcStore.fetchCalculations("underground", Number(projectId));
    calcStore.fetchCalculationsGraph("open_mining_co2", Number(projectId));
    calcStore.fetchCalculationsGraph2("regres_methane", Number(projectId));
    return () => {
      calcStore.clean();
    };
  }, [projectId]);

  //Подземная
  useEffect(() => {
    if (calcStore.calculations.length > 0) {
      calcStore.calculations.forEach(async (calculation) => {
        await calcStore.fetchCalculation(
          "underground",
          Number(projectId),
          calculation.id
        );

        setCalcUndergroundResults((prevResults) => {
          // Check if the result already exists in the state
          const exists = prevResults.some(
            (result) => result.id === calcStore.calculation.id
          );

          // Add the result only if it doesn't already exist
          return exists ? prevResults : [...prevResults, calcStore.calculation];
        });
      });
    }
  }, [calcStore.calculations, projectId]);

  //Открытая
  useEffect(() => {
    if (calcStore.calculationsGraph.length > 0) {
      calcStore.calculationsGraph.forEach(async (calculation) => {
        await calcStore.fetchCalculationGraph(
          "open_mining_co2",
          Number(projectId),
          calculation.id
        );
        if (String(calcStore.calculationGraph.ef) === "3.026000") {
          setCalcOpenFuelResults((prevResults) => {
            const exists = prevResults.some(
              (result) => result.id === calcStore.calculationGraph.id
            );

            return exists
              ? prevResults
              : [...prevResults, calcStore.calculationGraph];
          });
        } else if (String(calcStore.calculationGraph.ef) === "3.149000") {
          setCalcOpenDieselResults((prevResults) => {
            const exists = prevResults.some(
              (result) => result.id === calcStore.calculationGraph.id
            );

            return exists
              ? prevResults
              : [...prevResults, calcStore.calculationGraph];
          });
        }
      });
    }
  }, [calcStore.calculationsGraph, projectId]);

  //Временные ряды
  useEffect(() => {
    if (calcStore.calculationsGraph2.length > 0) {
      calcStore.calculationsGraph2.forEach(async (calculation) => {
        await calcStore.fetchCalculationGraph2(
          "regres_methane",
          Number(projectId),
          calculation.id
        );
      });
    }
  }, [calcStore.calculationsGraph2, projectId]);

  useEffect(() => {
    if (calcStore.calculationGraph2) {
      setCalcRegressBlanksResult((prevResults) => {
        // Check if the result already exists in the state
        const exists = prevResults.some(
          (result) => result.id === calcStore.calculationGraph2.id
        );

        // Add the result only if it doesn't already exist
        return exists
          ? prevResults
          : [...prevResults, calcStore.calculationGraph2];
      });
    }
  }, [calcStore.calculationGraph2]);

  // Данные для графика подземной добычи
  const undergroundChartData = {
    labels: calcUndergroundResults.map(
      (result, index) => `Расчет: ${result.name}`
    ),
    datasets: [
      {
        label: "Выбросы CO2 (Подземная добыча)",
        data: calcUndergroundResults.map((result) => result?.res_co2 ?? 0),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Выбросы CH4 (Подземная добыча)",
        data: calcUndergroundResults.map((result) => result?.res_ch4 ?? 0),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  // Данные для графика открытой добычи (дизель vs топливо)
  const openMiningChartData = {
    labels: calcOpenDieselResults.map(
      (result, index) => `Расчет: ${result.name}`
    ),
    datasets: [
      {
        label: "Выбросы CO2 (Дизель)",
        data: calcOpenDieselResults.map((result) => result?.res_co2 ?? 0),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
      {
        label: "Выбросы CO2 (Топливо)",
        data: calcOpenFuelResults.map((result) => result?.res_co2 ?? 0),
        backgroundColor: "rgba(255, 206, 86, 0.6)",
      },
    ],
  };

  //Данные для графика восстановления временных рядов
  const timeChartData = {
    labels: calcRegressgBlanksResult
      .map((result) => {
        const resultData = result.data.map((result2, index) => index + 1); // Fixed line
        return resultData;
      })
      .flat(), // Flatten into a single array
    datasets: [
      {
        label: "Временной ряд",
        data: calcRegressgBlanksResult
          .map((result) => {
            const resultData = result.data.map((result2) => result2.result);
            return resultData;
          })
          .flat(), // Flatten into a single array
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        fill: true,
      },
      {
        label: "Граница 1 процента",
        data: calcRegressgBlanksResult
          .map((result) => result.data.map(() => 1))
          .flat(), // Fill with `1` and flatten into a single array
        backgroundColor: "rgba(255,0,0,1)",
        borderColor: "rgba(255,0,0,1)", // Изменено на красный цвет
        borderWidth: 1,
        pointRadius: 0,
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 2,
        ticks: {
          stepSize: 0.2,
        },
        title: {
          display: true,
          text: "Проценты (%)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Дни",
        },
      },
    },
  };

  const renderChart = () => {
    try {
      return (
        <Line
          data={timeChartData}
          options={options}
          style={{ height: "300px", width: "50%" }}
        />
      );
    } catch (error) {
      console.error("Error rendering chart:", error);
      return <p>Unable to display chart.</p>;
    }
  };

  return (
    <Col>
      <Row className="row-bg">
        <Col>
          <Row className="mb-2 add-border">
            {commonStore.isLoading ? (
              <Loading />
            ) : (
              <Col className="mt-2 mb-2">
                Инфографика - {projectStore.selectedProject?.name}
              </Col>
            )}
          </Row>
          <Row>
            <Row>
              {/* График для подземной добычи */}
              <Col>
                <Bar
                  data={undergroundChartData}
                  options={{ responsive: true }}
                  style={{ height: "300px", width: "50%" }}
                />
              </Col>
              {/* График для открытой добычи (дизель vs топливо) */}
              <Col>
                <Bar
                  data={openMiningChartData}
                  options={{ responsive: true }}
                  style={{ height: "300px", width: "50%" }}
                />
              </Col>
              <Col>{renderChart()}</Col>
            </Row>
            <Row></Row>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default observer(Graph);

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import { appStore } from "../../../Store/appStore";
import HandlingUndergroundCalculation from "./components/HandlingUndergroundCalculation";
import {
  IDisposalCO2StatDictionary,
  IDisposalCO2TorchDictionary,
  IHandlingUndergroundCH4Dictionary,
  IOpenMiningCH4Dictionary,
  IOpenMiningCO2Dictionary,
  ITorchCH4Dictionary,
  IUndergroundContentDictionary,
  IUndergroundDictionary,
} from "../../../StoreCollections/dictionaryStore";
import OpenMiningCH4Calculation from "./components/OpenMiningCH4Calculation";
import OpenMiningCO2Calculation from "./components/OpenMiningCO2Calculation";
import UndergroundCalculation from "./components/UndergroundCalculation";
import UndergroundEmissionCalculation from "./components/UndergroundEmissionCalculation";
import RegresMethaneCalculation from "./components/RegresMethaneCalculation";
import DisposalCO2StatCalculation from "./components/DisposalCO2StatCalculation";
import DisposalCO2TorchCalculation from "./components/DisposalCO2TorchCalculation";
import TorchCH4Calculation from "./components/TorchCH4Calculation";
import FillingBlankCalculation from "./components/FillingBlankCalculation";
import SensitivityCalculation from "./components/SensitivityCalculation";

interface ICreateSolveProps {
  show: boolean;
  project_id: number;
  selectedType: string;
  mode: string;
  selectedCalculation: any;
  onClose: () => void;
  onCreate: (createSolve: any) => void;
  onEdit: (editSolve: any) => void;
}

const CreateEditCalculation: React.FC<ICreateSolveProps> = ({
  show,
  project_id,
  selectedType,
  mode,
  selectedCalculation,
  onClose,
  onCreate,
  onEdit,
}) => {
  const { calcStore, commonStore } = appStore;
  ///Dictionary
  const [
    selectedDisposalCO2StatDictionary,
    setSelectedDisposalCO2StatDictionary,
  ] = useState<IDisposalCO2StatDictionary | null>(null);
  const [
    selectedDisposalCO2TorchDictionary,
    setSelectedDisposalCO2TorchDictionary,
  ] = useState<IDisposalCO2TorchDictionary | null>(null);
  const [selectedTorchCH4Dictionary, setSelectedTorchCH4Dictionary] =
    useState<ITorchCH4Dictionary | null>(null);
  const [selectedDictionary, setSelectedDictionary] = useState<
    IHandlingUndergroundCH4Dictionary | IOpenMiningCH4Dictionary | null
  >(null);

  const [selectedDictionaryCO2, setSelectedDictonaryCO2] =
    useState<IOpenMiningCO2Dictionary | null>(null);

  const [selectedDictionaryUnderground, setSelectedDictonaryUnderground] =
    useState<IUndergroundDictionary | null>(null);

  const [
    selectedDictionaryUndergroundContent,
    setSelectedDictionaryUndergroundContent,
  ] = useState<IUndergroundContentDictionary | null>(null);
  ///Variables
  const [name, setName] = useState("");
  const [volume, setVolume] = useState<number>(0);
  const [emission, setEmission] = useState<number>(0);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [modalSize, setModalSize] = useState<"sm" | "lg" | "xl" | undefined>(
    undefined
  );
  const [sourceName, setSourceName] = useState("");
  const [elementName, setElementName] = useState("");
  const [regresCustomData, setRegresCustomData] = useState<any>();
  ///Visibility
  const [handlingUndergroundVisible, setHandlingUndergroundVisible] =
    useState(false);
  const [openMiningCH4Visible, setOpenMiningCH4Visible] = useState(false);
  const [openMiningCO2Visible, setOpenMiningCO2Visible] = useState(false);
  const [undergroundVisible, setUndergroundVisible] = useState(false);
  const [undergroundEmissionVisible, setUndergroundEmissionVisible] =
    useState(false);
  const [regresMethaneVisible, setRegresMethaneVisible] = useState(false);
  const [fillingBlanksVisible, setFillingBlanksVisible] = useState(false);
  const [disposalCO2StatVisible, setDisposalCO2StatVisible] = useState(false);
  const [disposalCO2TorchVisible, setDisposalCO2TorchVisible] = useState(false);
  const [torchCH4Visible, setTorchCH4Visible] = useState(false);
  const [sensitivityVisible, setSensitivityVisible] = useState(false);
  ///
  useEffect(() => {
    if (selectedType === "regres_methane") {
      setModalSize("xl");
    } else {
      setModalSize(undefined);
    }
  }, [selectedType]);
  ///Выбор полей для отображения исходя из типа расчета
  useEffect(() => {
    if (show) {
      const visibilityMap: Record<
        string,
        React.Dispatch<React.SetStateAction<boolean>>
      > = {
        handling_underground_ch4: setHandlingUndergroundVisible,
        open_mining_ch4: setOpenMiningCH4Visible,
        open_mining_co2: setOpenMiningCO2Visible,
        underground: setUndergroundVisible,
        underground_emission_ch4: setUndergroundEmissionVisible,
        regres_methane: setRegresMethaneVisible,
        filling_blanks: setFillingBlanksVisible,
        disposal_co2_torch: setDisposalCO2TorchVisible,
        disposal_co2_stat_sources: setDisposalCO2StatVisible,
        torch_ch4: setTorchCH4Visible,
        sensitivity: setSensitivityVisible,
      };

      const setVisible = (type: keyof typeof visibilityMap): void => {
        const setter = visibilityMap[type];
        if (setter) {
          setter(true);
        }
      };

      setVisible(selectedType);
    }
  }, [selectedType, show]);

  ///Получение существующего расчета
  useEffect(() => {
    if (show) {
      if (mode === "edit") {
        calcStore.fetchCalculation(
          selectedType,
          project_id,
          selectedCalculation.id
        );
      }
    }
    return () => {
      calcStore.clean();
    };
  }, [show]);

  ///Инициализация данных существующего расчета
  useEffect(() => {
    if (show) {
      if (calcStore.calculation && mode === "edit") {
        setName(calcStore.calculation.name);
      }
    }
  }, [show, calcStore.calculation]);

  ///Очистка полей при закрытие модального окна
  const resetForm = () => {
    setName("");
    setSelectedMethod("");
    setSelectedFile(null);
    setDisposalCO2StatVisible(false);
    setDisposalCO2TorchVisible(false);
    setFillingBlanksVisible(false);
    setTorchCH4Visible(false);
    setHandlingUndergroundVisible(false);
    setOpenMiningCH4Visible(false);
    setOpenMiningCO2Visible(false);
    setUndergroundVisible(false);
    setUndergroundEmissionVisible(false);
    setRegresMethaneVisible(false);
    setSelectedDictionary(null);
    setSelectedDictonaryCO2(null);
    setSelectedDictonaryUnderground(null);
    setSelectedDictionaryUndergroundContent(null);
    setSelectedDisposalCO2StatDictionary(null);
    setSelectedDisposalCO2TorchDictionary(null);
    setSelectedTorchCH4Dictionary(null);
    setEmission(0);
    setVolume(0);
  };

  ///Создание или обновление расчета
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim()) {
      console.log("Пожалуйста, введите название расчета");
      return;
    }

    let calculation: any = {
      name,
      project: project_id,
    };

    switch (selectedType) {
      case "handling_underground_ch4":
      case "open_mining_ch4": {
        calculation = {
          ...calculation,
          volume,
          ef: Number(selectedDictionary?.ef),
          koef_transform: Number(selectedDictionary?.koef_transform),
        };
        break;
      }
      case "open_mining_co2": {
        calculation = {
          ...calculation,
          volume,
          ef: Number(selectedDictionaryCO2?.ef),
        };
        break;
      }
      case "underground": {
        calculation = {
          ...calculation,
          volume,
          p_co2: Number(selectedDictionaryUnderground?.p_co2),
          p_ch4: Number(selectedDictionaryUnderground?.p_ch4),
          ef_co2: Number(selectedDictionaryUndergroundContent?.ef_co2),
          ef_ch4: Number(selectedDictionaryUndergroundContent?.ef_ch4),
        };
        break;
      }
      case "underground_emission_ch4": {
        calculation = {
          ...calculation,
          volume,
          ef: emission,
          koef_transform: 0.67,
        };
        break;
      }
      case "regres_methane": {
        if (selectedFile) {
          calculation = {
            ...calculation,
            method: selectedMethod,
            file_data: selectedFile,
          };
        } else {
          calculation = {
            ...calculation,
            method: selectedMethod,
            data: regresCustomData,
          };
        }
        break;
      }
      case "filling_blanks": {
        if (selectedFile) {
          calculation = {
            ...calculation,
            name_value: sourceName,
            name_element: elementName,
            file_data: selectedFile,
          };
        }
        break;
      }
      case "sensitivity": {
        if (selectedFile) {
          calculation = {
            ...calculation,
            file_data: selectedFile,
          };
        }
        break;
      }
      case "disposal_co2_torch": {
        calculation = {
          ...calculation,
          volume,
          ef: selectedDisposalCO2TorchDictionary?.ef,
          koef_transform: selectedDisposalCO2TorchDictionary?.koef_transform,
        };
        break;
      }
      case "disposal_co2_stat_sources": {
        calculation = {
          ...calculation,
          volume,
          ef: selectedDisposalCO2StatDictionary?.ef,
          of: selectedDisposalCO2StatDictionary?.of,
          k: selectedDisposalCO2StatDictionary?.k,
        };
        break;
      }
      case "torch_ch4": {
        calculation = {
          ...calculation,
          volume,
          koef_transform: selectedTorchCH4Dictionary?.koef_transform,
        };
        break;
      }
      default:
        console.error("Неверный тип расчета");
        return;
    }
    if (mode === "edit") {
      calculation = {
        ...calculation,
        id: selectedCalculation.id ?? 0,
      };
      console.log("submit", calculation);
      onEdit(calculation);
    } else {
      onCreate(calculation);
    }
    onClose();
  };

  ///Выбранные элементы из дополнительных полей
  const handleSourceChange = (newSourceName: string) => {
    setSourceName(newSourceName);
  };

  const handleElementChange = (newElementName: string) => {
    setElementName(newElementName);
  };

  const handleSelectedDictionaryChange = (
    newValue:
      | IHandlingUndergroundCH4Dictionary
      | IOpenMiningCH4Dictionary
      | null
  ) => {
    setSelectedDictionary(newValue);
  };

  const handleSelectedDictonaryDisposalCO2StatChange = (
    newValue: IDisposalCO2StatDictionary | null
  ) => {
    setSelectedDisposalCO2StatDictionary(newValue);
  };

  const handleSelectedDictonaryDisposalCO2TorchChange = (
    newValue: IDisposalCO2TorchDictionary | null
  ) => {
    setSelectedDisposalCO2TorchDictionary(newValue);
  };

  const handleSelectedDictonaryTorchCH4Change = (
    newValue: ITorchCH4Dictionary | null
  ) => {
    setSelectedTorchCH4Dictionary(newValue);
  };

  const handleSelectedDictonaryCO2Change = (
    newValue: IOpenMiningCO2Dictionary | null
  ) => {
    setSelectedDictonaryCO2(newValue);
  };

  const handleSelectedDictionaryUnderground = (
    newValue: IUndergroundDictionary | null
  ) => {
    setSelectedDictonaryUnderground(newValue);
  };

  const handleSelectedDictionaryUndergroundContend = (
    newValue: IUndergroundContentDictionary | null
  ) => {
    setSelectedDictionaryUndergroundContent(newValue);
  };

  const handleVolumeChange = (newVolume: number) => {
    setVolume(newVolume);
  };

  const handleEmissionChange = (newEmission: number) => {
    setEmission(newEmission);
  };

  const handleMethodChange = (newMethod: string) => {
    setSelectedMethod(newMethod);
  };

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
  };

  const handleRegresData = (data: any) => {
    setRegresCustomData(data);
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      size={modalSize}
      onExited={() => {
        resetForm();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {mode === "edit" ? "Редактировать расчет" : "Создать новый расчет"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {commonStore.isLoading ? (
          <></>
        ) : (
          <>
            {" "}
            <Form.Label>Название расчета</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </>
        )}

        {handlingUndergroundVisible && (
          <HandlingUndergroundCalculation
            onSelectedDictionaryChange={handleSelectedDictionaryChange}
            onVolumeChange={handleVolumeChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {openMiningCH4Visible && (
          <OpenMiningCH4Calculation
            onSelectedDictionaryChange={handleSelectedDictionaryChange}
            onVolumeChange={handleVolumeChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {openMiningCO2Visible && (
          <OpenMiningCO2Calculation
            onSelectedDictionaryChange={handleSelectedDictonaryCO2Change}
            onVolumeChange={handleVolumeChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {undergroundVisible && (
          <UndergroundCalculation
            onSelectedDictionaryChange={handleSelectedDictionaryUnderground}
            onSelectedDictionaryContentChange={
              handleSelectedDictionaryUndergroundContend
            }
            onVolumeChange={handleVolumeChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {undergroundEmissionVisible && (
          <UndergroundEmissionCalculation
            onVolumeChange={handleVolumeChange}
            onEmissionChange={handleEmissionChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {regresMethaneVisible && (
          <RegresMethaneCalculation
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
            onMethodChange={handleMethodChange}
            onFileChange={handleFileChange}
            onCustomDataChange={handleRegresData}
          />
        )}
        {disposalCO2StatVisible && (
          <DisposalCO2StatCalculation
            onSelectedDictionaryChange={
              handleSelectedDictonaryDisposalCO2StatChange
            }
            onVolumeChange={handleVolumeChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {disposalCO2TorchVisible && (
          <DisposalCO2TorchCalculation
            onSelectedDictionaryChange={
              handleSelectedDictonaryDisposalCO2TorchChange
            }
            onVolumeChange={handleVolumeChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {torchCH4Visible && (
          <TorchCH4Calculation
            onSelectedDictionaryChange={handleSelectedDictonaryTorchCH4Change}
            onVolumeChange={handleVolumeChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {fillingBlanksVisible && (
          <FillingBlankCalculation
            onFileChange={handleFileChange}
            mode={mode}
            onSourceNameChange={handleSourceChange}
            onElementNameChange={handleElementChange}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
        {sensitivityVisible && (
          <SensitivityCalculation
            onFileChange={handleFileChange}
            mode={mode}
            selectedCalculation={
              calcStore.calculation ? calcStore.calculation : undefined
            }
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Stack gap={3}>
          <Button
            className="mt-3"
            style={{ backgroundColor: "green" }}
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            {mode === "edit" ? "Сохранить изменения" : "Создать расчет"}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Закрыть
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(CreateEditCalculation);

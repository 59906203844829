import { makeObservable, runInAction } from "mobx";
import { ICommonStore } from "../Store/commonStore";
import http from "../Pages/HttpServices/http"
import { jwtDecode } from "jwt-decode";

export interface IUserStore {
    login: (email: string, password: string, guardant_code: string) => Promise<boolean>;
    isAuth?: boolean;
  }
interface ILoginResponse {
    refresh: string;
    access: string;
  }
interface ILoginRefresh{
  access: string
}

interface IUserCompany{
  id: number;
  name: string;
  inn: number;
  ogrn: number;
  okvd: string;
  region: string;
  email: string;
  created_at: Date;
}
interface IUserInfo{
  id: number | undefined;
  email: string;
  first_name: string;
  last_name: string
  guardant_code: string
  company: IUserCompany;
}
interface IStatistics{
  count_projects_user: number;
  count_projects_company: number;
  count_calc_user: number;
  count_calc_company: number;
}
  

export class UserStore implements IUserStore {
    public isAuth?: boolean;
    public user?: IUserInfo;
    public statistics?: IStatistics
    constructor(private common: ICommonStore){
        makeObservable(this);
    }
    public infoClear = () =>{
      this.user = undefined;
    }
    public statClear = () =>{
      this.statistics = undefined;
    }

    public getStat = async () =>{
      try{
        this.common.setIsLoading(true);
        const counter = await http.get<IStatistics>(
          "/v1/users/statistics/"
        )
        runInAction(() => (this.statistics = counter));
      }
      catch(error){
        console.log(error)
      }
      finally{
        this.common.setIsLoading(false);
      }
    }

    public getInfo = async () =>{
      try{
        this.common.setIsLoading(true);
        const user = await http.get<IUserInfo>(
          "/v1/users/get_me/"
        )
        runInAction(() => (this.user = user));
      }
      catch(error){
        console.log(error);
      }
      finally{
        console.log(this.user);
        this.common.setIsLoading(false);
      }
    }
    public login = async (email: string, password: string, guardant_code: string) => {
        try {
          this.common.setIsLoading(true);
          const user = await http.post<ILoginResponse>(
            `token/`,
            {
              email,
              password,
              guardant_code,
            }
          );
          localStorage.setItem("token", user.access);
          localStorage.setItem("refreshToken", user.refresh)
          runInAction(() => (this.isAuth = true));
          return true;
        } catch (error) {
          runInAction(() => (this.isAuth = false));
          return false;
        } finally {
          this.common.setIsLoading(false);
        }
      };
    
      public logout = () => {
        runInAction(() => {
          this.isAuth = false;
          localStorage.clear();
        });
      };
      
      public tokenRefresh = async (token: string | undefined) => {
        try {
          this.common.setIsLoading(true);
          const user = await http.post<ILoginRefresh>(
            `token/refresh/`,
            {
              token,
            }
          );
          localStorage.setItem("token", user.access);
          runInAction(() => (this.isAuth = true));
          return true;
        } catch (error) {
          runInAction(() => (this.isAuth = false));
          return false;
        } finally {
          this.common.setIsLoading(false);
        }
      }
      public checkToken = () => {
        const jwtToken: string | null = localStorage.getItem("token");
    
        if (jwtToken === null) {
          this.isAuth = false;
        }
    
        if (jwtToken) {
          const decodedToken: any = jwtDecode(jwtToken);
    
          if (decodedToken.exp! > Date.now() / 1000) {
            this.isAuth = true;
            console.log("token valid")
            return true;
          } else {
            try{
              this.tokenRefresh(localStorage.getItem("refreshToken")?.toString())
              return true;
            }
            catch(e){
              this.isAuth = false;
              localStorage.clear();
              return false;
            }
          }
        } else {
          this.isAuth = false;
          return false;
        }
      };
    
}
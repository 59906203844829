import { observer } from "mobx-react-lite";
import { Container, Row } from "react-bootstrap";
import { NavigationSystem } from "../Sidebar/Sidebar";
import Main from "../Main/Main";
import "./Home.css";

export const Home = observer(() => {
  return (
    <Container fluid>
      <Row>
        <NavigationSystem />
        <Main />
      </Row>
    </Container>
  );
});

import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import FuggitiveTransfer from "./components/FuggitiveTransfer";
import FuelConversion from "./components/FuelConversion";
import { InlineMath } from "react-katex";

interface WidgetProps {}

const Widgets: React.FC<WidgetProps> = observer(() => {
  const [selectedOption, setSelectedOption] = useState("fugitive");

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Col>
        <Row>
          <Col className="mt-3">Конвертер</Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <Form>
              <Form.Group controlId="conversionType">
                <Form.Label>Выберите тип конвертации:</Form.Label>
                <Form.Select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="text-center"
                >
                  <option value="fugitive">
                    Перевод СН4 и СО2 из <InlineMath math="м^{3}" /> в т
                  </option>
                  <option value="fuel">
                    Перевод топлива из л в т через плотность
                  </option>
                </Form.Select>
              </Form.Group>
            </Form>

            {selectedOption === "fugitive" ? (
              <FuggitiveTransfer />
            ) : (
              <FuelConversion />
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
});

export default Widgets;

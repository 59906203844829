import { DictionaryStore } from "../StoreCollections/dictionaryStore";
import { ProjectsStore } from "../StoreCollections/projectStore";
import { CalculationStore } from "../StoreCollections/calcStore";
import { UserStore } from "../StoreCollections/userStore";
import { CommonStore } from "./commonStore";

class AppStore {
  public commonStore = new CommonStore();
  public userStore = new UserStore(this.commonStore);
  public projectStore = new ProjectsStore(this.commonStore, this.userStore);
  public calcStore = new CalculationStore(this.commonStore);
  public dictionaryStore = new DictionaryStore(this.commonStore);
}

export const appStore = new AppStore();
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Pagination, Stack, Table } from "react-bootstrap";
import { appStore } from "../../../Store/appStore";
import Loading from "../../Loading/Loading";
import { IBlanksCreate } from "../../../StoreCollections/calcStore";

interface IShowCalculationProps {
  show: boolean;
  project_id: number;
  selectedType: string;
  selectedCalculation: any;
  onClose: () => void;
}

const ShowCalculation: React.FC<IShowCalculationProps> = ({
  show,
  project_id,
  selectedType,
  selectedCalculation,
  onClose,
}) => {
  const { calcStore, commonStore } = appStore;
  ///Visibility
  const [handlingUndergroundVisible, setHandlingUndergroundVisible] =
    useState(false);
  const [openMiningCH4Visible, setOpenMiningCH4Visible] = useState(false);
  const [openMiningCO2Visible, setOpenMiningCO2Visible] = useState(false);
  const [undergroundVisible, setUndergroundVisible] = useState(false);
  const [undergroundEmissionVisible, setUndergroundEmissionVisible] =
    useState(false);
  const [regresMethaneVisible, setRegresMethaneVisible] = useState(false);

  const [modalSize, setModalSize] = useState<"sm" | "lg" | "xl" | undefined>(
    undefined
  );
  const [fillingBlanksVisible, setFillingBlanksVisible] = useState(false);
  const [disposalCO2StatVisible, setDisposalCO2StatVisible] = useState(false);
  const [disposalCO2TorchVisible, setDisposalCO2TorchVisible] = useState(false);
  const [torchCH4Visible, setTorchCH4Visible] = useState(false);
  const [sensitivityVisible, setSenitiviteVisible] = useState(false);

  //activePage
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const itemsPerPage = 5;
  const fixedValue = 2;

  const [years, setYears] = useState<number[]>([]);

  const [methodsRegres] = useState([
    { id: 1, name: "xgboost" },
    { id: 2, name: "lightgbm" },
  ]);

  const headerRegresTitle = [
    { title: "#" },
    {
      title: "Результат",
    },
    {
      title:
        "Расход воздуха в исходящей струе лавы Qоч, м3/мин (по данным телеинформации)",
    },
    {
      title:
        "Расход воздуха в исходящей струе лавы Qоч, м3/мин (по данным суточных замеров)",
    },
    { title: "Концентрация метана в отсасываемой смеси, %" },
    { title: "Расход смеси, отсасываемой установкой, м3/мин" },
    { title: "Средняя концентрация СН4 на выхлопе ГОУ Свцг, %" },
    { title: "Расход воздуха на выхлопе ГОУ Qвцг, м3/мин" },
    { title: "Расход метана, отсасываемого установкой, м3/мин" },
  ];
  ///

  useEffect(() => {
    if (
      selectedType === "regres_methane" ||
      selectedType === "filling_blanks" ||
      selectedType === "sensitivity"
    ) {
      setModalSize("xl");
    } else {
      setModalSize(undefined);
    }
  }, [selectedType]);

  ///Выбор полей для отображения исходя из типа расчета
  useEffect(() => {
    if (show) {
      const visibilityMap: Record<
        string,
        React.Dispatch<React.SetStateAction<boolean>>
      > = {
        handling_underground_ch4: setHandlingUndergroundVisible,
        open_mining_ch4: setOpenMiningCH4Visible,
        open_mining_co2: setOpenMiningCO2Visible,
        underground: setUndergroundVisible,
        underground_emission_ch4: setUndergroundEmissionVisible,
        regres_methane: setRegresMethaneVisible,
        filling_blanks: setFillingBlanksVisible,
        disposal_co2_torch: setDisposalCO2TorchVisible,
        disposal_co2_stat_sources: setDisposalCO2StatVisible,
        torch_ch4: setTorchCH4Visible,
        sensitivity: setSenitiviteVisible,
      };

      const setVisible = (type: keyof typeof visibilityMap): void => {
        const setter = visibilityMap[type];
        if (setter) {
          setter(true);
        }
      };

      setVisible(selectedType);
    }
  }, [selectedType, show]);

  ///Получение существующего расчета
  useEffect(() => {
    if (show) {
      calcStore.fetchCalculation(
        selectedType,
        project_id,
        selectedCalculation.id
      );
    }
    return () => {
      calcStore.clean();
    };
  }, [show]);

  useEffect(() => {
    try {
      if (
        selectedType === "regres_methane" &&
        calcStore.calculation &&
        calcStore.calculation.data
      ) {
        setTotalPages(
          Math.ceil(calcStore.calculation.data.lenght / itemsPerPage)
        );
      }

      if (
        selectedType === "filling_blanks" &&
        calcStore.calculation &&
        calcStore.calculation.elements
      ) {
        setYears(
          (prevYears) =>
            Array.from(
              new Set(
                calcStore.calculation.elements.flatMap((element: any) =>
                  element.data.map((item: any) => item.year)
                )
              )
            ).sort((a: unknown, b: unknown) => {
              if (typeof a === "number" && typeof b === "number") {
                return a - b;
              }
              throw new Error("Invalid year type");
            }) as number[]
        );
      }
      if (
        selectedType === "sensitivity" &&
        calcStore.calculation &&
        calcStore.calculation.elements
      ) {
        setYears(
          (prevYears) =>
            Array.from(
              new Set(
                calcStore.calculation.elements.flatMap((element: any) =>
                  element.data_sensitivity.map((item: any) => item.year)
                )
              )
            ).sort((a: unknown, b: unknown) => {
              if (typeof a === "number" && typeof b === "number") {
                return a - b;
              }
              throw new Error("Invalid year type");
            }) as number[]
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [calcStore.calculation]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const paginatedElements = useMemo(() => {
    try {
      if (
        selectedType === "filling_blanks" ||
        (selectedType === "sensitivity" && calcStore.calculation)
      ) {
        const start = (currentPage - 1) * itemsPerPage;
        return calcStore.calculation.elements.slice(
          start,
          start + itemsPerPage
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, calcStore.calculation]);

  const paginatedItems = useMemo(() => {
    try {
      if (selectedType === "regres_methane" && calcStore.calculation) {
        const start = (currentPage - 1) * itemsPerPage;
        return calcStore.calculation.data.slice(start, start + itemsPerPage);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, calcStore.calculation]);

  const [selectedOption, setSelectedOption] = useState("объем");
  const dataProperties: { [key: string]: string } = {
    объем: "volume",
    метан: "methane",
    "Выбросы CO2": "res_co2",
    "Выбросы при переработке и транспортировке": "res_co2_transport",
    "Полные выбросы": "res_co2_all",
    "Чувствительность фугитивных выбросов по объему добычи": "sn_volume",
    "Чувствительность фугитивных выбросов по метанообильности": "sn_methan",
  };

  ///Очистка полей при закрытие модального окна
  const resetForm = () => {
    setHandlingUndergroundVisible(false);
    setOpenMiningCH4Visible(false);
    setOpenMiningCO2Visible(false);
    setUndergroundVisible(false);
    setUndergroundEmissionVisible(false);
    setRegresMethaneVisible(false);
    setFillingBlanksVisible(false);
    setDisposalCO2StatVisible(false);
    setDisposalCO2TorchVisible(false);
    setSenitiviteVisible(false);
    setTorchCH4Visible(false);
    setCurrentPage(1);
  };

  ///Выбранные элементы из дополнительных полей

  return (
    <Modal
      show={show}
      onHide={onClose}
      size={modalSize}
      onExited={() => {
        resetForm();
      }}
    >
      <Modal.Header>
        <Modal.Title>Результаты расчета</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {commonStore.isLoading ? (
          <Loading />
        ) : (
          <>
            {calcStore.calculation && (
              <>
                {" "}
                <Form.Label>Название расчета</Form.Label>
                <Form.Control
                  type="text"
                  value={calcStore.calculation.name}
                  disabled
                />
              </>
            )}
          </>
        )}

        {handlingUndergroundVisible && calcStore.calculation && (
          <>
            <Form.Label>Выбросы CH4, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(
                calcStore.calculation.res_ch4 * Math.pow(10, -3)
              ).toFixed(fixedValue)}
              disabled
            />
            <Form.Label>Выбросы CH4 переведенные в CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(
                calcStore.calculation.res_ch4_to_co2 * Math.pow(10, -3)
              ).toFixed(fixedValue)}
              disabled
            />
          </>
        )}
        {openMiningCH4Visible && calcStore.calculation && (
          <>
            <Form.Label>Выбросы CH4, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(
                calcStore.calculation.res_ch4 * Math.pow(10, -3)
              ).toFixed(fixedValue)}
              disabled
            />
            <Form.Label>Выбросы CH4 переведенные в CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(
                calcStore.calculation.res_ch4_to_co2 * Math.pow(10, -3)
              ).toFixed(fixedValue)}
              disabled
            />
          </>
        )}
        {disposalCO2StatVisible && calcStore.calculation && (
          <>
            <Form.Label>Выбросы CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.res_co2).toFixed(fixedValue)}
              disabled
            />
          </>
        )}
        {disposalCO2TorchVisible && calcStore.calculation && (
          <>
            <Form.Label>Выбросы CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.res_co2).toFixed(fixedValue)}
              disabled
            />
          </>
        )}
        {torchCH4Visible && calcStore.calculation && (
          <>
            <Form.Label>Выбросы CH4, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.res_ch4).toFixed(fixedValue)}
              disabled
            />
            <Form.Label>Выбросы CH4, переведенные в CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.res_ch4_to_co2).toFixed(
                fixedValue
              )}
              disabled
            />
          </>
        )}
        {openMiningCO2Visible && calcStore.calculation && (
          <>
            <Form.Label>Выбросы CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.res_co2).toFixed(fixedValue)}
              disabled
            />
          </>
        )}
        {undergroundVisible && calcStore.calculation && (
          <>
            <Form.Label>Выбросы CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.res_co2).toFixed(fixedValue)}
              disabled
            />
            <Form.Label>Выбросы CH4, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.res_ch4).toFixed(fixedValue)}
              disabled
            />
            <Form.Label>Выбросы CH4 переведенные в CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.res_ch4_to_co2).toFixed(
                fixedValue
              )}
              disabled
            />
            <Form.Label>
              Сумарные выбросы (CO2+CH4, переведенные в CO2), т
            </Form.Label>
            <Form.Control
              type="text"
              value={Number(calcStore.calculation.sum_co2).toFixed(fixedValue)}
              disabled
            />
          </>
        )}
        {undergroundEmissionVisible && calcStore.calculation && (
          <>
            <Form.Label>Выбросы CH4, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(
                calcStore.calculation.res_ch4 * Math.pow(10, -3)
              ).toFixed(fixedValue)}
              disabled
            />
            <Form.Label>Выбросы CH4 переведенные в CO2, т</Form.Label>
            <Form.Control
              type="text"
              value={Number(
                calcStore.calculation.res_ch4_to_co2 * Math.pow(10, -3)
              ).toFixed(fixedValue)}
              disabled
            />
          </>
        )}
        {fillingBlanksVisible && calcStore.calculation && (
          <>
            <div className="data-table-container">
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Шахта</th>
                    {years.map((year) => (
                      <th key={year}>{year}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedElements &&
                    paginatedElements.map((element: any) => (
                      <tr key={element.id}>
                        <td>{element.name}</td>
                        {years.map((year) => {
                          const value =
                            element.data.find((item: any) => item.year === year)
                              ?.value || "-";
                          return <td key={year}>{value}</td>;
                        })}
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Pagination className="justify-content-center mt-3">
                {[
                  ...Array(
                    Math.ceil(
                      calcStore.calculation.elements.length / itemsPerPage
                    )
                  ),
                ].map((_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i + 1 === currentPage}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </>
        )}
        {sensitivityVisible && calcStore.calculation && (
          <>
            <Form.Label>Тип результата</Form.Label>
            <Form.Select
              className="mb-3"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {Object.keys(dataProperties).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            <div className="data-table-container">
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Шахта</th>
                    {years.map((year) => (
                      <th key={year}>{year}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedElements &&
                    paginatedElements.map((element: any) => (
                      <tr key={element.id}>
                        <td>{element.name}</td>
                        {years.map((year) => {
                          let value =
                            element.data_sensitivity.find(
                              (item: any) => item.year === year
                            )?.[dataProperties[selectedOption]] || "-";
                          return <td key={year}>{value}</td>;
                        })}
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Pagination className="justify-content-center mt-3">
                {[
                  ...Array(
                    Math.ceil(
                      calcStore.calculation.elements.length / itemsPerPage
                    )
                  ),
                ].map((_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i + 1 === currentPage}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </>
        )}
        {regresMethaneVisible && calcStore.calculation && (
          <>
            <Form.Label>Метод расчета</Form.Label>
            <Form.Control
              type="text"
              value={calcStore.calculation.method}
              disabled
            />
            <Form.Label>Данные</Form.Label>
            <Table striped>
              <thead>
                <tr>
                  {headerRegresTitle.map((option) => (
                    <th key={option.title}>
                      {option.title.length <= 25 ? (
                        <span>{option.title}</span>
                      ) : (
                        <span title={option.title}>
                          {option.title.slice(0, 25)}...
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedItems.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    {Object.entries(item)
                      .slice(1)
                      .map(([key, value]) => (
                        <td key={key}>
                          <Form.Label>{value as string | number}</Form.Label>
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination className="mt-3 item-row-pagination">
              {Array.from(
                {
                  length: Math.ceil(
                    Number(calcStore.calculation.data.length) / itemsPerPage
                  ),
                },
                (_, i) => (
                  <Pagination.Item
                    onClick={() => handlePageChange(i + 1)}
                    key={i}
                    active={i + 1 === currentPage}
                  >
                    {i + 1}
                  </Pagination.Item>
                )
              )}
            </Pagination>
          </>
        )}
        <Stack gap={3} className="mt-3">
          <Button variant="secondary" onClick={onClose}>
            Закрыть
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default observer(ShowCalculation);

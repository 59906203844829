import { makeAutoObservable, runInAction } from "mobx";
import { ICommonStore } from "../Store/commonStore";
import http from "../Pages/HttpServices/http";
import { UserStore } from "./userStore";

export interface IStatus{
    id: number;
    name: string;
}
export interface IProject{
    id: number;
    status: IStatus;
    name: string;
    start_date: string;
    end_date: string;
    created_at: string;
    is_active: boolean;
    is_deleted: boolean;
    company: number;
    user: number;
}
export interface IProjectNew{
    name: string;
    start_date: string | undefined;
    end_date: string | undefined;
    status: number;
    company: number | undefined;
    user: number | undefined;
}
export interface IProjectUpdate{
    id: number;
    name: string;
    start_date: string;
    end_date: string;
}

export interface IProjectStore {
    projects: IProject[];
    selectedProject: IProject | null;
    setSelectedProject: (project: IProject | null) => void;
    fetchProject:  (project_id: number) => Promise<void>;
    fetchProjects: () => Promise<void>;
    fetchArchiveProjects: () => Promise<void>;
    createProject: (item: IProjectNew) => Promise<IProject | undefined>;
    updateProject: (item: IProjectUpdate) => Promise<IProject | undefined>;
    offset: number;
    limit: number;
    count: number;
}

export class ProjectsStore implements IProjectStore{
    public offset: number = 0;
    public limit: number = 10;
    public count: number = 0;
    public projects: IProject[] = [];
    public selectedProject: IProject | null = null;

    constructor(private common: ICommonStore, private userStore: UserStore){
        makeAutoObservable(this);
    }

    public setOffset(value: number) {
        this.offset = value;
      }

    public clean() {
        this.projects = [];
        this.offset = 0;
      }

    public setSelectedProject = (project: IProject | null) =>{
        runInAction(() => (this.selectedProject = project))
    }

    public fetchProject = async (project_id: number) =>{
        try{
            this.common.setIsLoading(true);
            const project = await http.get<IProject>(
                `/v1/projects/${project_id}`
            );
            runInAction(() => this.setSelectedProject(project))
        }
        catch(error){
            console.log(error);
        }
        finally{
            this.common.setIsLoading(false);
        }
    }

    public deleteProject = async (project: IProject | null) => {
        try {
            this.common.setIsLoading(true);
            project!.is_deleted = true;
            project!.is_active = false;
            if (project && project.id) {
                await http.patch<IProject, IProjectUpdate>(
                    `/v1/projects/${project.id}/`,
                    project
                );
                runInAction(() => {this.projects = this.projects.filter((project) => project.id !== (project as any).id);});
            } else {
                console.error('Invalid project object');
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.common.setIsLoading(false);
        }
    }

    public updateProject = async (data: IProjectUpdate) =>{
        try{
            this.common.setIsLoading(true);
            const project = await http.patch<IProject, IProjectUpdate>(
                `/v1/projects/${data.id}/`,
                data
            )
            return project
        }
        catch(error){
            console.log(error)
        }
        finally{
            this.common.setIsLoading(false)
        }
    }

    public createProject = async (data: IProjectNew) =>{
        try{
            this.common.setIsLoading(true);
            const project = await http.post<IProject, IProjectNew>(
                "/v1/projects/create_project/",
                data
            );
            return project;
        }
        catch(error){
            console.log("error")
        }
        finally{
            this.common.setIsLoading(false);
        }
    }

    public fetchProjects = async () =>{
        try{
            this.common.setIsLoading(true);
            await this.userStore.getInfo();
            const res = await http.get<{ count: number; next: null | string; previous: null | string; results: IProject[] }>(
                `/v1/projects/?company=${this.userStore.user?.company.id}&is_active=true&is_deleted=false&limit=${this.limit}&offset=${this.offset}`
            )
            runInAction(() => {
                this.projects = res.results
                this.count = res.count;
            })
        }
        catch(error)
        {
            if (`${error}`.includes("data not found")) {
                runInAction(() => (this.projects = []));
              } else {
                console.warn("error", "Ошибка загрузки проектов");
              }
        }
        finally{
            this.common.setIsLoading(false);
        }
    }
    public fetchArchiveProjects = async () =>{
        try{
            this.common.setIsLoading(true);
            await this.userStore.getInfo();
            const res = await http.get<{ count: number; next: null | string; previous: null | string; results: IProject[] }>(
                `/v1/projects/?company=${this.userStore.user?.company.id}&is_active=true&is_deleted=true&limit=${this.limit}&offset=${this.offset}`
            )
            runInAction(() => {
                this.projects = res.results
                this.count = res.count;
            })
        }
        catch(error)
        {
            if (`${error}`.includes("data not found")) {
                runInAction(() => (this.projects = []));
              } else {
                console.warn("error", "Ошибка загрузки проектов");
              }
        }
        finally{
            this.common.setIsLoading(false);
        }
    }
}
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import menuImage from "../../Style/image/menu.svg";
import { observer } from "mobx-react-lite";
import { appStore } from "../../Store/appStore";
import { SidebarSoucreFooter, SidebarSource } from "./SidebarSource";
import "./Sidebar.css";

export const NavigationSystem = observer(() => {
  const { userStore } = appStore;
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);

  const showSidebar = () => setSidebar(!sidebar);

  const handleNavigate = (item: any) => {
    navigate(item.navigate);
  };
  const handleNavigateLogout = (item: any) => {
    navigate(item.navigate);
    userStore.logout();
  };

  return (
    <Col xs="auto" className={sidebar ? "nav-main active" : "nav-main"}>
      <div className="nav-header">
        <img
          src={menuImage}
          className="nav-header-image"
          onClick={showSidebar}
        />
      </div>
      <div className={sidebar ? "nav-items active" : "nav-items"}>
        {SidebarSource.map((item, index) => (
          <div
            key={index}
            className="nav-item"
            onClick={() => handleNavigate(item)}
          >
            <img src={item.image} className="nav-item-image" alt="" />
            {!sidebar ? (
              <label className="nav-item-label">{item.header}</label>
            ) : (
              ""
            )}
          </div>
        ))}
        {SidebarSoucreFooter.map((item, index) => (
          <div
            key={index}
            className="nav-item-footer mt-auto"
            onClick={() => handleNavigateLogout(item)}
          >
            <img src={item.image} className="nav-item-image" />
            {!sidebar ? (
              <label className="nav-item-label-footer">{item.header}</label>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </Col>
  );
});

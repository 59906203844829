import { makeAutoObservable, runInAction } from "mobx";

export interface ICommonStore {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export class CommonStore implements ICommonStore {
  public isLoadingCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return !!this.isLoadingCount;
  }

  public setIsLoading = (isLoading: boolean) => {
    runInAction(() =>
      isLoading ? this.isLoadingCount++ : this.isLoadingCount--
    );
  };

}

import { Col, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "../ProtectedRoute/ProtectedRoute";
import { observer } from "mobx-react-lite";
import ProjectsList from "../Projects/ProjectRegistry/ProjectsList";
import CalculationRegistry from "../Calculations/CalculationRegistry";
import About from "../About/About";
import "./Main.css";
import Dictionary from "../Dictionary/Dictionary";
import Documents from "../Documents/Documents";
import ExportFile from "../ExportFile/ExportFile";
import Settings from "../Settings/Settings";
import Profile from "../Profile/Profile";
import Widgets from "../Widgets/Widgets";
import Graph from "../Graph/Graph";

const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
  authenticationPath: "/login",
};
const Main = () => {
  return (
    <>
      <Col>
        <Row className="row-empty"></Row>
        <Row className="row-main">
          <Routes>
            <Route
              path="/projects"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<ProjectsList />}
                />
              }
            />
            <Route
              path="/projects/:projectId/calculations"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<CalculationRegistry />}
                />
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<About />}
                />
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<Profile />}
                />
              }
            />
            <Route
              path="/dictionary"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<Dictionary />}
                />
              }
            />
            <Route
              path="/documents"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<Documents />}
                />
              }
            />
            <Route
              path="/export"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<ExportFile />}
                />
              }
            />
            <Route
              path="/widgets"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<Widgets />}
                />
              }
            />
            <Route
              path="/projects/:projectId/graphs"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<Graph />}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<Settings />}
                />
              }
            />
          </Routes>
        </Row>
      </Col>
    </>
  );
};
export default observer(Main);

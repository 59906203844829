import { makeAutoObservable, runInAction } from "mobx";
import { ICommonStore } from "../Store/commonStore";
import http from "../Pages/HttpServices/http";
import { NumberLiteralType } from "typescript";
export interface IBase{
    id: number;
    name: string;
    volume: number;
    is_active: boolean;
    is_deleted: boolean;
    created_at: string;
    project: number;
}
export interface IBaseCalculation extends IBase{
    ef: number;
}
//HandlingUndergroundCH4//
export interface IHandlingUndergroundCH4 extends IBaseCalculation{
    koef_transform: number;
    res_ch4: number;
    res_ch4_to_co2: number;
}
export interface INewHandlingUndergroundCH4{
    name: string;
    project: number;
    volume: number;
    ef: number;
    koef_transform: number;
}
export interface IUpdateHandlingUndergroundCH4{
    name: string;
    volume: number;
    ef: number;
    koef_transfrom: number;
    res_ch4: number;
    res_ch4_to_co2: number;
    is_active: boolean;
    is_deleted: boolean;
    project: number;
}
//ITorchCH4
export interface ITorchCH4 extends IBase{
    koef_transfrom: number;
    res_ch4: number;
    res_ch4_to_co2: number;
}
export interface INewTorchCH4{
    name: string;
    project: number;
    volume: number;
    koef_transfrom: number;
}
export interface IUpdateTorchCH4{
    name: string;
    volume: number;
    koef_transform: number;
    res_ch4: number;
    res_ch4_to_co2: number;
    is_active: boolean;
    is_deleted: boolean;
    project: number;
}
//IDisposalCO2Stat
export interface IDisposalCO2Stat extends IBaseCalculation{
    of: number;
    k: number;
    res_co2: number;
}
export interface INewDisposalCO2Stat{
    name: string;
    project: number;
    volume: number;
    ef: number;
    of: number;
    k: number;
}
export interface IUpdateDisposalCO2Stat{
    name: string;
    volume: number;
    ef: number;
    of: number;
    k: number;
    res_co2: number;
    is_active: boolean;
    is_deleted: boolean;
    project: number;
}
//IDisposalCO2Torch
export interface IDisposalCO2Torch extends IBaseCalculation{
    koef_transform: number;
    res_co2: number;
}
export interface INewDisposalCO2Torch{
    name: string;
    project: number;
    volume: number;
    ef: number;
    koef_transform: number;
}
export interface IUpdateDisposalCO2Torch{
    name: string;
    volume: number;
    ef: number;
    koef_transform: number;
    res_co2: number;
    is_active: boolean;
    is_deleted: boolean;
    project: number;
}
//OpenMiningCH4//
export interface IOpenMiningCH4 extends IBaseCalculation{
    koef_transform: string;
    res_ch4: number;
    res_ch4_to_co2: number;
}

export interface INewOpenMiningCH4{
    name: string;
    project: number;
    volume: number;
    ef: number;
    koef_transform: number;
}

export interface IUpdateOpenMiningCH4{
    name: string;
    volume: number;
    ef: number;
    koef_transform: number;
    res_ch4: number;
    res_ch4_to_co2: number;
    is_active: boolean;
    is_deleted: boolean;
    project: number;
}
//OpenMiningCO2//
export interface IOpenMiningCO2 extends IBaseCalculation{
    res_co2: number;
}

export interface INewOpenMiningCO2{
    name: string;
    project: number;
    volume: number;
    ef: number;
}

export interface IUpdateOpenMiningCO2{
    name: string;
    volume: number;
    ef: number;
    res_co2: number;
    is_active: boolean;
    is_deleted: boolean;
    project: number;
}
//Underground//
export interface IUnderground extends IBaseCalculation{
    p_co2: number;
    p_ch4: number;
    ef_co2: number;
    ef_ch4: number;
    res_co2: number;
    res_ch4: number;
    res_ch4_to_co2: number;
    sum_co2: number;
}

export interface INewUnderground{
    name: string;
    project: number;
    volume: number;
    p_co2: number;
    p_ch4: number;
    ef_co2: number;
    ef_ch4: number;
}
export interface IUpdateUnderground{
    name: string;
    volume: string;
    p_co2: number;
    p_ch4: number;
    ef_co2: number;
    ef_ch4: number;
    res_co2: number;
    res_ch4: number;
    res_ch4_to_co2: number;
    sum_co2: number;
    is_active: boolean;
    is_deleted: boolean;
    project: number;
}
//UndergroundEmissionCH4//
export interface IUndergroundEmissionCH4 extends IBaseCalculation{
    koef_transform: string;
    res_ch4: string;
    res_ch4_to_co2: string;
}
export interface INewUndergroundEmissionCH4{
    name: string;
    project: number;
    volume: number;
    ef: number;
    koef_transform: number;
}
export interface IUpdateUndergroundEmissionCH4{
    name: string;
    volume: number;
    ef: number;
    koef_transform: number;
    res_ch4: number;
    res_ch4_to_co2: number;
    is_active: boolean;
    is_deleted: boolean;
    project: number;
}
//RegresMethane//
export interface RegresData{
    x1: number;
    x2: number;
    x7: number;
    x8: number;
    x12: number;
    x13: number;
    x15: number;
}
export interface RegresDataResult extends RegresData{
    id: number;
    result: number;
}
export interface INewRegresMethane{
    name: string;
    project: number;
    method: string;
    data: RegresData[];
}
export interface INewRegresMethaneWithFile{
    name: string;
    project: number;
    method: string;
    file_data: File;
}
export interface IRegress{
    created_at: string;
    data: RegresDataResult[];
    file_data: string;
    id: number;
    is_active: boolean;
    is_deleted: boolean;
    method: string;
    name: string;
    project: number;
}
//FillingBlanks
export interface IBlanksData{
    year: number;
    value: number;
}
export interface IBlanksCreate{
    name: string;
    data: IBlanksData[]
}
export interface INewFillingBlanks{
    name: string;
    project: number;
    name_value: string;
    name_element: string;
    elements: IBlanksCreate[];
}
//Sensitivity
export interface IDataCalcSensitivity{
    id: number;
    year: number;
    volume: number;
    methane: number;
    res_co2: number;
    res_co2_transport: number;
    res_co2_all: number;
    sn_volume: number;
    sn_methan: number;
}
export interface IElementCalcSensitivity{
    id: number;
    name: string;
    field_birth: string;
    data_sensitivity: IDataCalcSensitivity[];
}
export interface ISensitivity{
    id: number;
    elements: string;
    name: string;
    file_data: File;
    is_active: boolean;
    is_deleted: boolean;
    created_at: string;
    project: number;
}
//
export interface ICalculation{
    id: number;
    name: string;
    is_active: boolean;
    is_deleted: boolean;
    type: string | undefined | null;
}


export interface ICalculationStore{
    offset: number;
    limit: number;
    count: number;
    countGraph: number;
    countGraph2: number;
    calculation: any;
    calculationGraph: any;
    calculationGraph2: any;
    calculations: ICalculation[];
    calculationsGraph: ICalculation[];
    calculationsGraph2: ICalculation[];
    fetchCalculation:  <T>(type: string,project_id: number, calculation_id: number) => Promise<void>;
    fetchCalculationGraph:  <T>(type: string,project_id: number, calculation_id: number) => Promise<void>;
    fetchCalculationGraph2:  <T>(type: string,project_id: number, calculation_id: number) => Promise<void>;
    fetchCalculations: (type: string, project_id: number) => Promise<void>;
    fetchCalculationsGraph: (type: string, project_id: number) => Promise<void>;
    fetchCalculationsGraph2: (type: string, project_id: number) => Promise<void>;
    fetchArchiveCalculations: (type: string, project_id: number) => Promise<void>;
    createCalculationWithFile: <T>(
        type: string,
        item: T
      ) => Promise<T>;
    createCalculation: <T>(
        type: string,
        item: T
      ) => Promise<T>;
    
      updateCalculation: <T>(
        type: string,
        calculation_id: number,
        item: T
      ) => Promise<T>;
}

export class CalculationStore implements ICalculationStore{
    public calculations: ICalculation[] = [];

    public calculationsGraph: ICalculation[] = [];

    public calculationsGraph2: ICalculation[] = [];
    
    public calculation: any;

    public calculationGraph: any;

    public calculationGraph2: any;

    public offset: number = 0;

    public limit: number = 2;

    public count: number = 0;
    public countGraph: number = 0;
    public countGraph2: number = 0;
    
    constructor(private common: ICommonStore){
        makeAutoObservable(this);
    }
    public clean(){
        this.calculations = [];
        this.calculationsGraph = [];
        this.calculationsGraph2 = [];
        this.calculation = undefined;
        this.calculationGraph = undefined;
        this.calculationGraph2 = undefined;
    }

    public setOffset(value: number) {
        this.offset = value;
      }
    
    public fetchCalculation = async <T>(type: string, project_id: number, calculation_id: number): Promise<void> => {
        try {
            this.common.setIsLoading(true);
            const res = await http.get<T>(`/v1/${type}/${project_id}/single_calc/${calculation_id}/`);
            runInAction(() => this.calculation = res)
        } catch (error) {
            console.log("Error creating calculation:", error);
        } finally {
            this.common.setIsLoading(false);
        }
    }
    
    public fetchCalculations = async (type: string, project_id: number) => {
        try {
            this.common.setIsLoading(true);
            const res = await http.get<{ count: number; next: null | string; previous: null | string; results: ICalculation[] }>(
                `/v1/${type}/?project=${project_id}&is_active=true&is_deleted=false&limit=${this.limit}&offset=${this.offset}`
            );
            console.log(res);
            runInAction(() => {this.calculations = res.results; this.count = res.count;});
        } catch (error) {
            console.log(error);
        } finally {
            this.common.setIsLoading(false);
        }
    };

    public fetchCalculationGraph = async <T>(type: string, project_id: number, calculation_id: number): Promise<void> => {
        try {
            this.common.setIsLoading(true);
            const res = await http.get<T>(`/v1/${type}/${project_id}/single_calc/${calculation_id}/`);
            runInAction(() => this.calculationGraph = res)
        } catch (error) {
            console.log("Error creating calculation:", error);
        } finally {
            this.common.setIsLoading(false);
        }
    }
    
    public fetchCalculationsGraph = async (type: string, project_id: number) => {
        try {
            this.common.setIsLoading(true);
            const res = await http.get<{ count: number; next: null | string; previous: null | string; results: ICalculation[] }>(
                `/v1/${type}/?project=${project_id}&is_active=true&is_deleted=false&limit=${this.limit}&offset=${this.offset}`
            );
            console.log(res);
            runInAction(() => {this.calculationsGraph = res.results; this.countGraph = res.count;});
        } catch (error) {
            console.log(error);
        } finally {
            this.common.setIsLoading(false);
        }
    };

    public fetchCalculationGraph2 = async <T>(type: string, project_id: number, calculation_id: number): Promise<void> => {
        try {
            this.common.setIsLoading(true);
            const res = await http.get<T>(`/v1/${type}/${project_id}/single_calc/${calculation_id}/`);
            runInAction(() => {
                console.log("fetchCalculationGraph2");console.log(res);
                this.calculationGraph2 = res})
        } catch (error) {
            console.log("Error creating calculation:", error);
        } finally {
            this.common.setIsLoading(false);
        }
    }
    
    public fetchCalculationsGraph2 = async (type: string, project_id: number) => {
        try {
            this.common.setIsLoading(true);
            const res = await http.get<{ count: number; next: null | string; previous: null | string; results: ICalculation[] }>(
                `/v1/${type}/?project=${project_id}&is_active=true&is_deleted=false&limit=${this.limit}&offset=${this.offset}`
            );
            console.log(res);
            runInAction(() => {this.calculationsGraph2 = res.results; this.countGraph2 = res.count;});
        } catch (error) {
            console.log(error);
        } finally {
            this.common.setIsLoading(false);
        }
    };
    public fetchArchiveCalculations = async (type: string, project_id: number) => {
        try {
            this.common.setIsLoading(true);
            const res = await http.get<{ count: number; next: null | string; previous: null | string; results: ICalculation[] }>(
                `/v1/${type}/?project=${project_id}&is_active=true&is_deleted=true&limit=${this.limit}&offset=${this.offset}`
            );
            console.log(res);
            runInAction(() => {this.calculations = res.results; this.count = res.count;});
        } catch (error) {
            console.log(error);
        } finally {
            this.common.setIsLoading(false);
        }
    };
    public updateCalculation = async <T>(type: string,calculation_id: number, item: T): Promise<T> =>{
        try{
            this.common.setIsLoading(true);
            const res = await http.patch<T,T>(
                `/v1/${type}/${calculation_id}/`,
                item
            )
            return res;
        }
        catch(error){
            console.error("Error update calculation:", error);
            throw new Error('Calculation update failed');
        }
        finally{
            this.common.setIsLoading(false);
        }
    }

    public updateRegresDataCalculation = async <T>(type: string,calculation_id: number, item: T): Promise<T> =>{
        try{
            this.common.setIsLoading(true);
            const res = await http.patch<T,T>(
                `/v1/${type}/${calculation_id}/data_update/`,
                item
            )
            return res;
        }
        catch(error){
            console.error("Error update data calculation:", error);
            throw new Error('Calculation update data failed');
        }
        finally{
            this.common.setIsLoading(false);
        }
    }
    public createCalculation = async <T>(type: string, item: T): Promise<T> => {
        try {
            console.log("createCalculation")
            console.log(item);
            this.common.setIsLoading(true);
            const res = await http.post<T, T>(
                `/v1/${type}/`,
                item,
            );
            return res;
        } catch (error) {
            console.error("Error creating calculation:", error);
            throw new Error('Calculation creation failed');
        } finally {
            this.common.setIsLoading(false);
        }
    };

    public createCalculationWithFile = async <T>(type: string, item: T): Promise<T> => {
        try {
            this.common.setIsLoading(true);

            console.log("createCalculationWithFile")
            console.log(item);

            const res = await http.postWithFile<T, T>(
                `/v1/${type}/`,
                item,
            );
            return res;
        } catch (error) {
            console.error("Error creating calculation:", error);
            throw new Error('Calculation creation failed');
        } finally {
            this.common.setIsLoading(false);
        }
    };

    public deleteCalculation = async <T>(type: string,calculation_id: number, item: T): Promise<T> =>{
        try{
            this.common.setIsLoading(true);
            (item as any).is_deleted = true;
            (item as any).is_active = false;
            const res = await http.patch<T,T>(
                `/v1/${type}/${calculation_id}/`,
                item
            )
            runInAction(() => {this.calculations = this.calculations.filter((calculation) => calculation.id !== (item as any).id);});
            
            return res;
        }
        catch(error){
            console.error("Error update calculation:", error);
            throw new Error('Calculation update failed');
        }
        finally{
            this.common.setIsLoading(false);
        }
    }
}
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useMemo } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import Loading from "../../../Loading/Loading";
import { RegresData } from "../../../../StoreCollections/calcStore";

interface RegresItem {
  id: number;
  x1: number;
  x2: number;
  x7: number;
  x8: number;
  x12: number;
  x13: number;
  x15: number;
}

interface RegresMethaneProps {
  mode: string;
  selectedCalculation: any;
  onMethodChange: (value: string) => void;
  onFileChange: (value: File) => void;
  onCustomDataChange: (value: any) => void;
}

const RegresMethaneCalculation: React.FC<RegresMethaneProps> = ({
  mode,
  selectedCalculation,
  onMethodChange,
  onFileChange,
  onCustomDataChange,
}) => {
  const { commonStore } = appStore;

  const [methods] = useState([
    { id: 1, name: "xgboost" },
    { id: 2, name: "lightgbm" },
  ]);

  const headerTitles = useMemo(
    () => [
      "#",
      "Расход воздуха в исходящей струе лавы Qоч, м3/мин (по данным телеинформации)",
      "Расход воздуха в исходящей струе лавы Qоч, м3/мин (по данным суточных замеров)",
      "Концентрация метана в отсасываемой смеси, %",
      "Расход смеси, отсасываемой установкой, м3/мин",
      "Средняя концентрация СН4 на выхлопе ГОУ Свцг, %",
      "Расход воздуха на выхлопе ГОУ Qвцг, м3/мин",
      "Расход метана, отсасываемого установкой, м3/мин",
    ],
    []
  );

  const [items, setItems] = useState<RegresItem[]>([
    { id: 1, x1: 0, x2: 0, x7: 0, x8: 0, x12: 0, x13: 0, x15: 0 },
  ]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isCustomData, setIsCustomData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [selectedMethod, setSelectedMethod] = useState(methods[0].name);
  const [selectedDataForm, setSelectedDataForm] = useState("custom");

  const totalPages = useMemo(
    () => Math.ceil(items.length / itemsPerPage),
    [items.length, itemsPerPage]
  );

  useEffect(() => {
    onCustomDataChange(items);
  }, [items, onCustomDataChange]);

  useEffect(() => {
    if (mode === "edit" && selectedCalculation) {
      const method = methods.find((m) => m.name === selectedCalculation.method);
      setSelectedMethod(method ? method.name : methods[0].name);
      setSelectedDataForm("custom");

      const itemsData = Object.entries(selectedCalculation.data)
        .filter(([key]) => key !== "result")
        .map(([_, value]) => {
          const item = value as Partial<RegresItem>; // Cast value to Partial<RegresItem>
          return {
            id: item.id || 0,
            x1: item.x1 || 0,
            x2: item.x2 || 0,
            x7: item.x7 || 0,
            x8: item.x8 || 0,
            x12: item.x12 || 0,
            x13: item.x13 || 0,
            x15: item.x15 || 0,
          };
        });

      setItems(itemsData);
    }
  }, [mode, selectedCalculation, methods]);

  useEffect(() => {
    onMethodChange(selectedMethod);
  }, [selectedMethod, onMethodChange]);

  useEffect(() => {
    if (selectedFile) onFileChange(selectedFile);
  }, [selectedFile, onFileChange]);

  const generateUniqueId = (): number => {
    const maxId = items.reduce((max, item) => Math.max(max, item.id), 0);
    return maxId + 1;
  };

  const handleAddItem = (): void => {
    const newItem = {
      id: generateUniqueId(),
      x1: 0,
      x2: 0,
      x7: 0,
      x8: 0,
      x12: 0,
      x13: 0,
      x15: 0,
    };
    setItems((prevItems) => [...prevItems, newItem]);
  };

  const handleRemoveItem = (id: number): void => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleInputChange = (
    id: number,
    field: keyof RegresItem,
    value: string
  ): void => {
    if (/^\d*\.?\d{0,4}$/.test(value)) {
      // Ограничение формата
      const elementIndex = items.findIndex((e) => e.id === id);
      const newItems = [...items];
      newItems[elementIndex] = {
        ...newItems[elementIndex],
        [field]: value, // Сохраняем как строку
      };
      setItems(newItems);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDataForm(event.target.value);
    setIsCustomData(event.target.value === "custom");
  };

  const displayItems = (): RegresItem[] =>
    items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  if (commonStore.isLoading) return <Loading text="Полей" />;

  return (
    <>
      <Form.Label>Метод расчета</Form.Label>
      <Form.Select
        value={selectedMethod}
        onChange={(e) => setSelectedMethod(e.target.value)}
      >
        {methods.map((option) => (
          <option key={option.id} value={option.name}>
            {option.name}
          </option>
        ))}
      </Form.Select>
      {mode !== "edit" && (
        <>
          <Form.Label>Формат данных</Form.Label>
          <Form.Select value={selectedDataForm} onChange={handleSelectChange}>
            <option value="custom">Фактические значения</option>
            <option value="file">Значения из файла</option>
          </Form.Select>
          {!isCustomData && (
            <Form.Control
              type="file"
              onChange={(e) =>
                setSelectedFile(
                  (e.target as HTMLInputElement).files?.[0] || null
                )
              }
            />
          )}
        </>
      )}
      {isCustomData && (
        <>
          {mode !== "edit" && (
            <Button
              className="mt-3"
              style={{ width: "100%" }}
              onClick={handleAddItem}
            >
              Добавить значение
            </Button>
          )}
          <Table striped>
            <thead>
              <tr>
                {headerTitles.map((title) => (
                  <th key={title} title={title}>
                    {title.length > 25 ? `${title.slice(0, 25)}...` : title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayItems().map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  {Object.entries(item)
                    .slice(1)
                    .map(([key, value]) => (
                      <td key={key}>
                        <Form.Control
                          type="text"
                          value={value.toString()}
                          onChange={(e) =>
                            handleInputChange(
                              item.id,
                              key as keyof (typeof items)[0],
                              e.target.value
                            )
                          }
                        />
                      </td>
                    ))}
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveItem(item.id)}
                    >
                      Удалить
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center mt-3">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    i + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default observer(RegresMethaneCalculation);

import { useEffect, useState } from "react";
import { Button, Col, Pagination, Row, Stack } from "react-bootstrap";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { appStore } from "../../../Store/appStore";
import { observer } from "mobx-react-lite";
import "./ProjectList.css";
import CreateProject from "../components/CreateProject";
import { IProject, IProjectNew } from "../../../StoreCollections/projectStore";
import UpdateProject from "../components/UpdateProject";
import Loading from "../../Loading/Loading";

const ProjectsList = () => {
  const { projectStore, userStore, commonStore } = appStore;
  const navigate = useNavigate();
  const location = useLocation();

  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [selectedProject, setSelectedProject] = useState<IProject | undefined>(
    undefined
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handleCreateProject = () => {
    setShowModalCreate(true);
  };

  const handleCloseCreateModal = () => {
    setShowModalCreate(false);
  };

  const handleEditProject = (project: IProject) => {
    setSelectedProject(project);
    setShowModalUpdate(true);
  };

  const handleShowCalc = async (id: number) => {
    const newPath = `${location.pathname}/${id}/calculations`;
    navigate(newPath);
  };
  const handleShowGraph = async (id: number) => {
    const newPath = `${location.pathname}/${id}/graphs`;
    navigate(newPath);
  };

  const handleUpdateProject = async (updatedProject: any) => {
    try {
      await projectStore.updateProject(updatedProject);
      console.log("Проект обновлен успешно");
      setShowModalUpdate(false);
      projectStore.fetchProjects(); // Обновить список после редактирования
    } catch (error) {
      console.error("Ошибка при обновлении проекта:", error);
    }
  };

  const handleCloseUpdateModal = () => {
    setSelectedProject(undefined);
    setShowModalUpdate(false);
  };

  const handleSubmitForm = async (formData: IProjectNew) => {
    try {
      await projectStore.createProject(formData);
      console.log(formData);
      handleCloseCreateModal();
      projectStore.fetchProjects();
    } catch (error) {
      console.error("Ошибка при создании проекта:", error);
    }
  };

  useEffect(() => {
    projectStore.fetchProjects();
    userStore.getStat();
    return () => {
      projectStore.clean();
      userStore.statClear();
    };
  }, []);

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    projectStore.setOffset((page - 1) * projectStore.limit);
    await projectStore.fetchProjects();
  };

  return (
    <>
      {showModalCreate && (
        <CreateProject
          show={true}
          onCreate={handleSubmitForm}
          onClose={handleCloseCreateModal}
        />
      )}

      {showModalUpdate && (
        <UpdateProject
          show={true}
          onUpdate={handleUpdateProject}
          onClose={handleCloseUpdateModal}
          selectedProject={selectedProject || ({} as IProject)}
        />
      )}
      <Col>
        <Row className="row-bg silverBG">
          <Col>
            <Row className="mb-2 add-border">
              <Col className="mt-2 mb-2 ">Проекты</Col>
            </Row>
            <Row className="mb-2 add-border">
              <Col className="mt-2 mb-2">
                <Button
                  className="item-buttom greenButton"
                  onClick={handleCreateProject}
                >
                  + Добавить
                </Button>
              </Col>
            </Row>
            {commonStore.isLoading ? (
              <Loading text="проектов" />
            ) : (
              <>
                <Row>
                  <Col className="header-border-start">
                    Наименование проекта
                  </Col>
                  <Col className="header-border">Дата начала проекта</Col>
                  <Col className="header-border">Дата окончания проекта</Col>
                  <Col className="header-border">Дата создания проекта</Col>
                  <Col className="header-border">Функции</Col>
                </Row>

                {projectStore.projects.map((project) => (
                  <Row
                    key={project.id}
                    className="mt-3 item-row"
                    onClick={() => handleShowCalc(project.id)}
                  >
                    <Col className="item-border-start">
                      <span className="card-title" title={project.name}>
                        {project.name.length > 25
                          ? `${project.name.substring(0, 25)}...`
                          : project.name}
                      </span>
                    </Col>
                    <Col className="item-border">
                      {format(new Date(project.start_date), "yyyy-MM-dd")}
                    </Col>
                    <Col className="item-border">
                      {format(new Date(project.end_date), "yyyy-MM-dd")}
                    </Col>
                    <Col className="item-border">
                      {format(new Date(project.created_at), "yyyy-MM-dd")}
                    </Col>
                    <Col className="item-border">
                      <Stack direction="horizontal" gap={2}>
                        <Button
                          className="item-buttom greenButton"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleShowGraph(project.id);
                          }}
                        >
                          Инфографика
                        </Button>
                        <Button
                          className="item-buttom yellowButton"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleEditProject(project);
                          }}
                        >
                          Редактировать
                        </Button>
                        <Button
                          className="item-buttom redButton"
                          onClick={async (event) => {
                            event.stopPropagation();
                            await projectStore.deleteProject(project);
                            await projectStore.fetchProjects();
                          }}
                        >
                          Удалить
                        </Button>
                      </Stack>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Pagination className="mt-3 item-row-pagination">
                    {Array.from(
                      {
                        length: Math.ceil(
                          Number(projectStore.count) / projectStore.limit
                        ),
                      },
                      (_, i) => (
                        <Pagination.Item
                          onClick={() => handlePageChange(i + 1)}
                          key={i}
                          active={i + 1 === currentPage}
                        >
                          {i + 1}
                        </Pagination.Item>
                      )
                    )}
                  </Pagination>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default observer(ProjectsList);

import { Button, Col, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const About = () => {
  const navigate = useNavigate();
  const handleNavigateProject = () => {
    navigate("/projects");
  };
  return (
    <>
      <Col className="text-center mt-3">
        <Stack className="stack-bg m-2">
          <label className="mb-3 mt-5">
            <h3>
              ФЕДЕРАЛЬНОЕ ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ НАУЧНОЕ УЧРЕЖДЕНИЕ
              «ФЕДЕРАЛЬНЫЙ ИССЛЕДОВАТЕЛЬСКИЙ ЦЕНТР УГЛЯ И УГЛЕХИМИИ СИБИРСКОГО
              ОТДЕЛЕНИЯ РОССИЙСКОЙ АКАДЕМИИ НАУК» (ИНСТИТУТ УГЛЯ ФИЦ УУХ СО РАН)
            </h3>
          </label>
          <label className="mb-3">
            <h2>
              ЦИФРОВАЯ ПЛАТФОРМА МОНИТОРИНГА ФУГИТИВНЫХ ВЫБРОСОВ ПАРНИКОВЫХ
              ГАЗОВ И ИХ СОКРАЩЕНИЙ ПРИ ИСПОЛЬЗОВАНИИ ЧИСТЫХ УГОЛЬНЫХ ТЕХНОЛОГИЙ
            </h2>
          </label>
          <label className="mb-3">
            Выполнено при финансовой поддержке Министерства науки и высшего
            образования Российской Федерации в рамках комплексной
            научно-технической программы полного инновационного цикла
            «Разработка и внедрение комплекса технологий в областях разведки и
            добычи полезных ископаемых, обеспечения промышленной безопасности,
            биоремедиации, создания новых продуктов глубокой переработки из
            угольного сырья при последовательном снижении экологической нагрузки
            на окружающую среду и рисков для жизни населения», утвержденной
            Распоряжением Правительства Российской Федерации от 11.05.2022 г.
            №1144-р (Соглашение № 075-15-2022-1196).
          </label>
          <Button
            style={{ backgroundColor: "green" }}
            className="m-2"
            onClick={handleNavigateProject}
          >
            Начать работу
          </Button>
        </Stack>
      </Col>
    </>
  );
};
export default observer(About);

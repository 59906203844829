import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import Loading from "../../../Loading/Loading";
import { RegresData } from "../../../../StoreCollections/calcStore";

interface fillingBlanksItem {
  id: number;
  year: number;
  value: number;
}

interface FillingBlanksProps {
  mode: string;
  selectedCalculation: any;
  onFileChange: (value: File) => void;
  onSourceNameChange: (value: any) => void;
  onElementNameChange: (value: any) => void;
}

const RegresMethaneCalculation: React.FC<FillingBlanksProps> = ({
  mode,
  selectedCalculation,
  onFileChange,
  onSourceNameChange,
  onElementNameChange,
}) => {
  ///Variables
  const { commonStore } = appStore;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [sourceName, setSourceName] = useState("");

  const [elementName, setElementName] = useState("");

  ///useEffects
  useEffect(() => {
    if (mode === "edit" && selectedCalculation) {
      setSourceName(selectedCalculation.name_value);
      setElementName(selectedCalculation.name_element);
      console.log(selectedCalculation);
    }
  }, [selectedCalculation]);

  useEffect(() => {
    if (selectedFile !== null) {
      onFileChange(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    onSourceNameChange(sourceName);
  }, [sourceName]);
  useEffect(() => {
    onElementNameChange(elementName);
  }, [elementName]);

  ///Methods

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  if (commonStore.isLoading) {
    return <Loading text="Полей" />;
  }

  return (
    <>
      <Form.Label>Название данных</Form.Label>
      <Form.Control
        type="text"
        value={sourceName}
        onChange={(e) => setSourceName(e.target.value)}
      />
      <Form.Label>Определение для элемента</Form.Label>
      <Form.Control
        type="text"
        value={elementName}
        onChange={(e) => setElementName(e.target.value)}
      />
      {mode !== "edit" && (
        <>
          <Form.Label>Файл с данными</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </>
      )}
    </>
  );
};

export default observer(RegresMethaneCalculation);

import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Pagination,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import "./Dictionary.css";
import "katex/dist/katex.min.css";

import { DictionarySource } from "./DictionarySource";
import { appStore } from "../../Store/appStore";
import Loading from "../Loading/Loading";
import { observer } from "mobx-react-lite";
import { InlineMath } from "react-katex";

const Dictionary = () => {
  const { dictionaryStore, commonStore } = appStore;
  const [activeRow, setActiveRow] = useState<number | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4); // Adjust this value as needed

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = DictionarySource.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(DictionarySource.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    dictionaryStore.fetchHandlingUndergroundCH4();
    dictionaryStore.fetchOpenMiningCH4();
    dictionaryStore.fetchOpenMiningCO2();
    dictionaryStore.fetchUnderground();
    dictionaryStore.fetchUndergroundContent();
    dictionaryStore.fetchUndergroundEmission();
    dictionaryStore.fetchDisposalCO2Stat();
    dictionaryStore.fetchDisposalCO2Torch();
    dictionaryStore.fetchTorch();
    return () => {
      dictionaryStore.clean();
    };
  }, []);

  const handleRowClick = (index: number) => {
    setActiveRow(index);
  };

  const renderTable = () => {
    switch (activeRow) {
      case 0:
        return (
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Плотность</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Условия измерений</Col>
                  <Col className="header-border">
                    <Stack direction="horizontal">
                      Плотность диоксида углерода(
                      <InlineMath math="\rho_{CO2}" />
                      ), кг/
                      <InlineMath math="м^{3}" />
                    </Stack>
                  </Col>
                  <Col className="header-border">
                    <Stack direction="horizontal">
                      Плотность метана(
                      <InlineMath math="\rho_{CH4}" />
                      ), кг/
                      <InlineMath math="м^{3}" />
                    </Stack>
                  </Col>
                </Row>
                {dictionaryStore.underground &&
                  dictionaryStore.underground.map((item, index) => {
                    if ("p_co2" in item && "p_ch4" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">{item.p_co2}</Col>
                          <Col className="item-border">{item.p_ch4}</Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
                <Row className="mt-2 mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Концентрация</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">
                    Вид углеводородной смеси
                  </Col>
                  <Col className="header-border">
                    Содержание диоксида углерода ( <InlineMath math="W_{CO2}" />
                    ), %об.
                  </Col>
                  <Col className="header-border">
                    Содержание метана (<InlineMath math="W_{CH4}" />
                    ), %об.
                  </Col>
                </Row>
                {dictionaryStore.undergroundContent &&
                  dictionaryStore.undergroundContent.map((item, index) => {
                    if ("ef_co2" in item && "ef_ch4" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">{item.ef_co2} w</Col>
                          <Col className="item-border">{item.ef_ch4} w</Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </Col>
            </Row>
          </Col>
        );
      case 1:
        return (
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Дополнительная информация</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Наименование</Col>
                  <Col className="header-border">
                    Коэффициент выбросов диоксида углерода (EF), тСО2/т (тыс.
                    <InlineMath math="м^{3}" />)
                  </Col>
                </Row>
                {dictionaryStore.openMiningCO2 &&
                  dictionaryStore.openMiningCO2.map((item, index) => {
                    if ("ef" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">{item.ef}</Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </Col>
            </Row>
          </Col>
        );
      case 2:
        return (
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Дополнительная информация</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Наименование</Col>
                  <Col className="header-border">
                    Коэффициент выбросов метана, <InlineMath math="м^{3}" />
                    /т
                  </Col>
                  <Col className="header-border">
                    Коэффициент преобразования (плотность метана), кг/
                    <InlineMath math="м^{3}" />
                  </Col>
                </Row>
                {dictionaryStore.handlingUndergroundCH4 &&
                  dictionaryStore.handlingUndergroundCH4.map((item, index) => {
                    if ("ef" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">{item.ef}</Col>
                          <Col className="item-border">
                            {item.koef_transform}
                          </Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </Col>
            </Row>
          </Col>
        );
      case 3:
        return (
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Дополнительная информация</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Наименование</Col>
                  <Col className="header-border">
                    Коэффициент выбросов метана, <InlineMath math="м^{3}" />
                    /т
                  </Col>
                </Row>
                {dictionaryStore.openMiningCH4 &&
                  dictionaryStore.openMiningCH4.map((item, index) => {
                    if ("ef" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">{item.ef}</Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </Col>
            </Row>
          </Col>
        );
      case 4:
        return (
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Дополнительная информация</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Наименование</Col>
                  <Col className="header-border">
                    Коэффициент преобразования (плотность метана), кг/
                    <InlineMath math="м^{3}" />
                  </Col>
                </Row>
                {dictionaryStore.undergroundEmission &&
                  dictionaryStore.undergroundEmission.map((item, index) => {
                    if ("koef_transform" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">
                            {item.koef_transform}
                          </Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </Col>
            </Row>
          </Col>
        );
      case 5:
        return (
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Дополнительная информация</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Наименование</Col>
                  <Col className="header-border">
                    Коэффициент преобразования (плотность метана), кг/
                    <InlineMath math="м^{3}" />
                  </Col>
                </Row>
                {dictionaryStore.torchCH4 &&
                  dictionaryStore.torchCH4.map((item, index) => {
                    if ("koef_transform" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">
                            {item.koef_transform}
                          </Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </Col>
            </Row>
          </Col>
        );
      case 6:
        return (
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Дополнительная информация</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Наименование</Col>
                  <Col className="header-border">
                    <label>
                      Коэффициент выбросов CO2 от сжигания топлива (
                      <InlineMath math="EF_{CO_{2,j,y}}" />
                      ), тCO2/т у.т
                    </label>
                  </Col>
                  <Col className="header-border">
                    <label>
                      Коэффициент окисления (<InlineMath math="OF_{j,y}" />
                      ), доля
                    </label>
                  </Col>
                  <Col className="header-border">
                    Коэффициент перевода расхода топлива в тонны условного
                    топлива
                    <InlineMath math="(к),(т у.т./ттыс.м^{3})" />
                  </Col>
                </Row>
                {dictionaryStore.disposalCO2Stat &&
                  dictionaryStore.disposalCO2Stat.map((item, index) => {
                    if ("ef" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">{item.ef}</Col>
                          <Col className="item-border">{item.of}</Col>
                          <Col className="item-border">{item.k}</Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </Col>
            </Row>
          </Col>
        );
      case 7:
        return (
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Дополнительная информация</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Наименование</Col>
                  <Col className="header-border">
                    Стехиометрический коэффициент массы
                  </Col>
                  <Col className="header-border">
                    Коэффициент преобразования (плотность метана), кг/
                    <InlineMath math="м^{3}" />
                  </Col>
                </Row>
                {dictionaryStore.disposalCO2Torch &&
                  dictionaryStore.disposalCO2Torch.map((item, index) => {
                    if ("koef_transform" in item) {
                      return (
                        <Row key={index} className="mt-3 item-row">
                          <Col className="item-border-start">{item.name}</Col>
                          <Col className="item-border">{item.ef}</Col>
                          <Col className="item-border">
                            {item.koef_transform}
                          </Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </Col>
            </Row>
          </Col>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {commonStore.isLoading ? (
        <Loading text="справочников" />
      ) : (
        <>
          {" "}
          <Col className="mt-3">
            <Row className="row-bg">
              <Col>
                <Row className="mb-2 add-border">
                  <Col className="mt-2 mb-2 ">Справочники</Col>
                </Row>
                <Row>
                  <Col className="header-border-start">Категория выбросов</Col>
                  <Col className="header-border">Формула</Col>
                  <Col className="header-border">Документация</Col>
                  <Col className="header-border">Тип расчета</Col>
                  <Col className="header-border">Статус</Col>
                </Row>
                {currentItems.map((item, index) => (
                  <Row
                    key={index}
                    onClick={() =>
                      handleRowClick(index + (currentPage - 1) * itemsPerPage)
                    }
                    className="mt-3 item-row"
                  >
                    <Col className="item-border-start">{item.category}</Col>
                    <Col className="item-border">{item.formula}</Col>
                    <Col className="item-border">{item.documentation}</Col>
                    <Col className="item-border">{item.type}</Col>
                    <Col className="item-border">{item.status}</Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <Row className="mt-2">
              <Pagination className="d-flex justify-content-center text-center">
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {[...pageNumbers].map((number) => (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                  >
                    {number}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage >= pageNumbers.length}
                />
              </Pagination>
            </Row>

            {renderTable()}
          </Col>
        </>
      )}
    </>
  );
};
export default observer(Dictionary);

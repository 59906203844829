import axios from "axios";

const createDownloadLink = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Utility function to handle API response and create Blob
  const handleApiResponse = async (response: any, mimeType: string, filename: string) => {
    if (response.status!== 200) {
      throw new Error('Network response was not ok');
    }
    const arrayBuffer = new Uint8Array(response.request.response).buffer;
    const blob = new Blob([arrayBuffer], { type: mimeType });
    createDownloadLink(blob, filename);
  };
  // Function to download file based on type
  export const downloadFile = async (type: string, mimeType: string, filename: string,id: number) => {
    try {
      const response = await axios.get(`http://91.222.238.12:8000/api/v1/projects/${id}/export_to_${type}/`, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      await handleApiResponse(response, mimeType, filename);
    } catch (error) {
      console.error(`Error downloading ${type} file:`, error);
    }
  };
  export const downloadExcelFile = async(type: string,mimeType: string,filename: string,id: number,details: boolean) =>{
    try {
      const response = await axios.get(`http://91.222.238.12:8000/api/v1/projects/${id}/export_to_${type}/?detail=${details}`, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      await handleApiResponse(response, mimeType, filename);
    } catch (error) {
      console.error(`Error downloading ${type} file:`, error);
    }
  }
  
  export const exportFile = async (filename: string, type: string,id: number,detail: boolean) => {
    try {
      let mimeType;
      switch (type) {
        case "txt":
          mimeType = 'text/plain;charset=UTF-8;';
          await downloadFile(type, mimeType, filename, id);
          break;
        case "xlsx":
          mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          await downloadExcelFile(type, mimeType, filename,id,detail);
          break;
        case "xls":
          mimeType = 'application/vnd.ms-excel';
          await downloadExcelFile(type, mimeType, filename,id,detail);
          break;
        default:
          throw new Error(`Unsupported file type: ${type}`);
      }
    } catch (error) {
      throw new Error("Failed to export project");
    }
  };
  
import { Col, Row, Table, Container, Stack, Form } from "react-bootstrap";

export default function Documents() {
  return (
    <Col className="mt-3">
      <Row className="row-bg">
        <Col>
          <Row className="mb-2 add-border">
            <Col className="mt-2 mb-2 ">Нормативные документы</Col>
          </Row>
        </Col>
        <Container>
          <Stack
            gap={1}
            className="mx-auto d-flex justify-content-center align-items-center"
          >
            <a
              href={`${process.env.PUBLIC_URL}/№296-ФЗ от 02.07.2021 Закон об ограничении ПГ.pdf`}
              download="№296-ФЗ от 02.07.2021 Закон об ограничении ПГ.pdf"
            >
              №296-ФЗ от 02.07.2021 Закон об ограничении ПГ
            </a>
            <a
              href={`${process.env.PUBLIC_URL}/gost__14064-1-2021.pdf`}
              download="gost__14064-1-2021.pdf"
            >
              ГОСТР ИСО 14064-1— 2021
            </a>
            <a
              href={`${process.env.PUBLIC_URL}/GOST_14064-2-2021.pdf`}
              download="GOST_14064-2-2021.pdf"
            >
              ГОСТР ИСО 14064-2— 2021
            </a>
            <a
              href={`${process.env.PUBLIC_URL}/gost_r_iso_14067-2021 УС.pdf`}
              download="gost_r_iso_14067-2021 УС.pdf"
            >
              ГОСТР ИСО 14067— 2021
            </a>
            <a
              href={`${process.env.PUBLIC_URL}/МГЭИК V2_4_Fugitive_Emissions.pdf`}
              download="МГЭИК V2_4_Fugitive_Emissions.pdf"
            >
              Руководящие принципы национальных инвентаризаций парниковых газов
              МГЭИК, 2006
            </a>
            <a
              href={`${process.env.PUBLIC_URL}/Приказ №371 от 29.07.2022.pdf`}
              download="Приказ №371 от 29.07.2022.pdf"
            >
              Приказ №371 от 29.07.2022
            </a>
            <a
              href={`${process.env.PUBLIC_URL}/Указ президента №666.pdf`}
              download="Указ президента №666.pdf"
            >
              Указ президента №666
            </a>
          </Stack>
        </Container>
      </Row>
    </Col>
  );
}

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { appStore } from "../../../../Store/appStore";
import { IOpenMiningCH4Dictionary } from "../../../../StoreCollections/dictionaryStore";
import Loading from "../../../Loading/Loading";
import { regex } from "./RegExpr";
import { InlineMath } from "react-katex";

interface OpenMiningCH4Props {
  onSelectedDictionaryChange: (value: IOpenMiningCH4Dictionary | null) => void;
  onVolumeChange: (value: number) => void;
  mode: string;
  selectedCalculation: any;
}

const OpenMiningCH4Calculation: React.FC<OpenMiningCH4Props> = ({
  onSelectedDictionaryChange,
  onVolumeChange,
  mode,
  selectedCalculation,
}) => {
  ///Variables
  const { dictionaryStore, commonStore } = appStore;

  const [selectedDictionary, setSelectedDictionary] =
    useState<IOpenMiningCH4Dictionary | null>(null);

  const [volume, setVolume] = useState("");

  const [isCustomEf, setIsCustomEf] = useState(true);

  const [customEf, setCustomEf] = useState("");

  ///useEffects
  useEffect(() => {
    dictionaryStore.fetchOpenMiningCH4();
    return () => {
      dictionaryStore.clean();
    };
  }, []);

  useEffect(() => {
    if (mode === "edit") {
      if (selectedCalculation) {
        setVolume(Number(selectedCalculation.volume).toFixed(4));
      }
    }
  }, [selectedCalculation]);

  useEffect(() => {
    if (
      dictionaryStore.openMiningCH4.length > 0 &&
      mode === "edit" &&
      selectedCalculation != undefined
    ) {
      const foundCalc = dictionaryStore.openMiningCH4.find(
        (calc: IOpenMiningCH4Dictionary) =>
          Number(calc.ef) === Number(selectedCalculation.ef) &&
          Number(calc.koef_transform) ===
            Number(selectedCalculation.koef_transform)
      );
      if (foundCalc) {
        setSelectedDictionary(foundCalc);
        setIsCustomEf(false);
      } else {
        setIsCustomEf(true);
        setCustomEf(selectedCalculation.ef);
      }
    }
  }, [dictionaryStore.openMiningCH4]);

  useEffect(() => {
    if (!isCustomEf) {
      onSelectedDictionaryChange(selectedDictionary);
    } else {
      const custom: IOpenMiningCH4Dictionary = {
        id: 0,
        name: "Фактическое значение",
        ef: Number(customEf),
        koef_transform: 0.67,
      };
      onSelectedDictionaryChange(custom);
    }
  }, [selectedDictionary, isCustomEf, customEf]);

  useEffect(() => {
    onVolumeChange(Number(volume));
  }, [volume]);

  ///Methods
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === "custom") {
      setIsCustomEf(true);
      setSelectedDictionary(null);
    } else {
      const selectedItem = dictionaryStore.openMiningCH4.find(
        (item) => item.id === Number(event.target.value)
      );
      setSelectedDictionary(selectedItem ?? null);
      setIsCustomEf(false);
    }
  };

  if (commonStore.isLoading) {
    return <Loading text="полей" />;
  }

  return (
    <>
      <Form.Label>Объем угля, добытого открытым способом, т</Form.Label>
      <Form.Control
        value={volume}
        onChange={(e) => {
          if (regex.test(e.target.value)) {
            setVolume(e.target.value);
          }
        }}
        type="text"
      />
      <Form.Label>
        Коэффициент выбросов CH4, <InlineMath math="м^{3}" />
        /т
      </Form.Label>
      <Form.Select
        value={selectedDictionary?.id || ""}
        onChange={handleSelectChange}
      >
        <option value="custom">Фактическое значение</option>
        {dictionaryStore.openMiningCH4.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
      {isCustomEf && (
        <>
          <Form.Label>Введите коэффициент выбросов CH4</Form.Label>
          <Form.Control
            value={customEf}
            onChange={(e) => {
              if (regex.test(e.target.value)) {
                setCustomEf(e.target.value);
              }
            }}
            type="text"
          />
        </>
      )}
    </>
  );
};

export default observer(OpenMiningCH4Calculation);
